import React from 'react';
import Layout from "../layout/layout";
// import MyForm from "../form/MyForm";
// import { useTranslation} from "react-i18next"
import {Helmet} from "react-helmet";

const Contact = () => {
    // const {t, i18n} = useTranslation();
    return (
        <>
            <Helmet>
                <meta name="description" content="Website development, Stationlei 71, 1800 Vilvoorde, Belgium." data-react-helmet="true"/>
                <title>Contact</title>
            </Helmet>
            {/*<div className="site">*/}
                <Layout/>

                <main className="main">
                    {/*<div className="container__wrapper">*/}
                        <div className="container ">
                            <div className="contact__flex">
                                {/*<MyForm/>*/}

                                <section className="contact">

                                    {/*<div className="address">*/}
                                    {/*    <div className="address__contact">*/}
                                    {/*        <h3>{t('contact.address')}</h3>*/}
                                    {/*        <p>Stationlei 71</p>*/}
                                    {/*        <p>1800 Vilvoorde</p>*/}
                                    {/*        <p>{t('contact.country')}</p>*/}
                                    {/*    </div>*/}

                                    {/*    <div className="address__phone__email" style={{marginLeft: 20}}>*/}
                                    {/*        <h3>{t('contact.phone')}</h3>*/}
                                    {/*        <a href="tel:+32 498 52 89 31">+32 498 52 89 31</a>*/}
                                    {/*        <h3>{t('contact.email')}</h3>*/}
                                    {/*        <a href="mailto:info@devcolibri.biz">info@devcolibri.biz</a>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </section>
                            </div>

                        {/*</div>*/}
                    </div>
                </main>

            {/*</div>*/}
        </>
    );
};

export default Contact;