import React, {useState} from 'react';
import WindowWidth from "../../../WindowWidth";
import { useTranslation } from 'react-i18next'
const MenuLeft = () => {
    const {i18n } = useTranslation()
    let lang = i18n.language
    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
    }
    const buttonData = [
        {
            id: '1',
            tabButton: 'Starters',
            tabButton_fr: 'Entrées',
            tabButton_nl: 'Voorgerechten',
        },
        {
            id: '2',
            tabButton: 'Salads',
            tabButton_fr: 'Salades',
            tabButton_nl: 'Salades',
        },
        {
            id: '3',
            tabButton: 'Pasta',
            tabButton_fr: 'Pâtes',
            tabButton_nl: 'Pâtes',
        },
        {
            id: '4',
            tabButton: 'Fish',
            tabButton_fr: 'Poisson',
            tabButton_nl: 'Vis',
        },
        {
            id: '5',
            tabButton: 'Meat',
            tabButton_fr: 'Viande',
            tabButton_nl: 'Vlees',
        },
        {
            id: '6',
            tabButton: 'Deserts',
            tabButton_fr: 'Starters',
            tabButton_nl: 'Starters',
        },
        {
            id: '7',
            tabButton: 'Pancakes',
            tabButton_fr: 'Crêpes',
            tabButton_nl: 'Pannenkoeken',
        },
        {
            id: '8',
            tabButton: 'Water',
            tabButton_fr: 'Eau',
            tabButton_nl: 'Eau',
        },
        {
            id: '9',
            tabButton: 'Soft Drinks',
            tabButton_fr: 'Boissons froides',
            tabButton_nl: 'Frisdranken',
        },
        {
            id: '10',
            tabButton: 'Fruit Juices',
            tabButton_fr: 'Jus de fruits',
            tabButton_nl: 'Fruitsappen',
        },
        {
            id: '11',
            tabButton: 'Hot Drinks',
            tabButton_fr: 'Boissons chaudes',
            tabButton_nl: 'Warme dranken',
        },
        {
            id: '12',
            tabButton: 'Special Tea',
            tabButton_fr: 'Thé spécial',
            tabButton_nl: 'Speciale thee',
        },
        {
            id: '13',
            tabButton: 'Cocktails',
            tabButton_fr: 'Cocktails',
            tabButton_nl: 'Cocktails',
        },
        {
            id: '14',
            tabButton: 'Aperitif',
            tabButton_fr: 'Apéritif',
            tabButton_nl: 'Aperitief',
        },
        {
            id: '15',
            tabButton: 'Gin',
            tabButton_fr: 'Gin',
            tabButton_nl: 'Gin',
        },
        {
            id: '16',
            tabButton: 'Ice Tea',
            tabButton_fr: 'Ice Tea',
            tabButton_nl: 'Ice Tea',
        },
        {
            id: '17',
            tabButton: 'Limonade',
            tabButton_fr: 'Limonade',
            tabButton_nl: 'Limonade',
        },
        {
            id: '18',
            tabButton: 'Mocktails',
            tabButton_fr: 'Mocktails',
            tabButton_nl: 'Mocktails',
        },
        {
            id: '19',
            tabButton: 'Smoothies',
            tabButton_fr: 'Smoothies',
            tabButton_nl: 'Smoothies',
        },
        {
            id: '20',
            tabButton: 'Beers',
            tabButton_fr: 'Bières',
            tabButton_nl: 'Bieren',
        },
        {
            id: '21',
            tabButton: 'Wine',
            tabButton_fr: 'Vin',
            tabButton_nl: 'Wijn',
        },
        {
            id: '22',
            tabButton: 'Bubbles',
            tabButton_fr: 'Des bulles',
            tabButton_nl: 'Bubbels',
        },
    ]

    const [visibleTab, setVisibleTab] = useState(buttonData[0].id)
    const listButtons = buttonData.map((item) => (
        <div
            key={item.id}
            onClick={() => setVisibleTab(item.id)}
            className={visibleTab === item.id ? 'menu-tab__button active' : 'menu-tab__button'}>
            {lang === 'en' && <a href={`#a${item.id}`}>{item.tabButton}</a>}
            {lang === 'fr' && <a href={`#a${item.id}`}>{item.tabButton_fr}</a>}
            {lang === 'nl' && <a href={`#a${item.id}`}>{item.tabButton_nl}</a>}
        </div>
    ))

    let {scrollPosition} = WindowWidth()

    return (

        <div className={scrollPosition > 250 ? 'menu-tabs-buttons active-buttons' : 'menu-tabs-buttons'}>
            <div className="wrapper_menu_tabs_buttons">
                {listButtons}
            </div>
            {scrollPosition > 200 &&
            <div className="menu-tabs-lang">
                <span className={i18n.language === 'en' ? 'active' : ''} onClick={() => changeLanguage('en')}>EN</span>
                <span className={i18n.language === 'fr' ? 'active' : ''} onClick={() => changeLanguage('fr')}>FR</span>
                <span className={i18n.language === 'nl' ? 'active' : ''} onClick={() => changeLanguage('nl')}>NL</span>
            </div>
            }
        </div>

    );
};

export default MenuLeft;