import React from 'react';
import {useTranslation} from 'react-i18next'

const MenuContentTodaySpecial = () => {
    const {i18n} = useTranslation()
    let lang = i18n.language

    const menuMonday = [
        {
            title:
                'Celery soup',
            price: '€ 6,00',
        },
        {
            title:
                'Meatloaf, green beans, potatoes, gravy',
            price: '€ 12,50',
        },
    ]
    const menuMonday_fr = [
        {
            title:
                'Potage céleri',
            price: '€ 6,00',
        },
        {
            title:
                'Pain de viande, haricots verts, pommes grenailles, jus de rôti',
            price: '€ 12,50',
        },
    ]
    const menuMonday_nl = [
        {
            title:
                'Selderijsoep',
            price: '€ 6,00',
        },
        {
            title:
                'Gehaktbrood, sperziebonen, krieltjes, vleesjus',
            price: '€ 12,50',
        },
    ]

    const menuTuesday = [
        {
            title:
                'Vegetable soup',
            price: '€ 6,00',
        },
        {
            title:
                'Pasta with 4 cheeses',
            price: '€ 12,50',
        },
    ]
    const menuTuesday_fr = [
        {
            title:
                'Potage légumes',
            price: '€ 6,00',
        },
        {
            title:
                'Pasta aux 4 fromages',
            price: '€ 12,50',
        },
    ]
    const menuTuesday_nl = [
        {
            title:
                'Groentensoep',
            price: '€ 6,00',
        },
        {
            title:
                'Pasta met 4 kazen',
            price: '€ 12,50',
        },
    ]

    const menuWednesday = [
        {
            title:
                'Pumpkin cream soup',
            price: '€ 6,00',
        },
        {
            title:
                'Chicken breast with curry and rice',
            price: '€ 12,50',
        },
    ]
    const menuWednesday_fr = [
        {
            title:
                'Mercredi',
            price: '€ 6,00',
        },
        {
            title:
                'Blanc de volaille au curry et riz',
            price: '€ 12,50',
        },
    ]
    const menuWednesday_nl = [
        {
            title:
                'Pompoenroomsoep',
            price: '€ 6,00',
        },
        {
            title:
                'Kipfilet met curry en rijst',
            price: '€ 12,50',
        },
    ]

    const menuThursday = [
        {
            title:
                'Broccoli soup',
            price: '€ 6,00',
        },
        {
            title:
                'Sauted pork with peppers and croquettes',
            price: '€ 12,50',
        },
    ]
    const menuThursday_fr = [
        {
            title:
                'Potage brocolis',
            price: '€ 6,00',
        },
        {
            title:
                'Sauté de porc aux poivrons et pommes croquettes ',
            price: '€ 12,50',
        },
    ]
    const menuThursday_nl = [
        {
            title:
                'Broccolisoep',
            price: '€ 6,00',
        },
        {
            title:
                'Gebakken varkensvlees met paprika en kroketten',
            price: '€ 12,50',
        },
    ]

    const menuFriday = [
        {
            title:
                'Creamy salmon soup',
            price: '€ 6,00',
        },
        {
            title:
                'Roasted salmon, béarnaise, salad and fries',
            price: '€ 12,50',
        },
    ]
    const menuFriday_fr = [
        {
            title:
                'Crème de saumon',
            price: '€ 6,00',
        },
        {
            title:
                'Rôti de saumon, béarnaise, salade et frites',
            price: '€ 12,50',
        },
    ]
    const menuFriday_nl = [
        {
            title:
                'Romige zalmsoep',
            price: '€ 6,00',
        },
        {
            title:
                'Zalmgebraad, bearnaise, salade en frietjes',
            price: '€ 12,50',
        },
    ]

    const menuSaturday = [
        {
            title:
                'Vegetable soup',
            price: '€ 6,00',
        },
        {
            title:
                'Carrot stoemp, farmer’s sausages',
            price: '€ 12,50',
        },
    ]
    const menuSaturday_fr = [
        {
            title:
                'Soupe aux légumes',
            price: '€ 6,00',
        },
        {
            title:
                'Stoemp carottes, saucisses de campagne',
            price: '€ 12,50',
        },
    ]
    const menuSaturday_nl = [
        {
            title:
                'Groentensoep',
            price: '€ 6,00',
        },
        {
            title:
                'Wortelstoemp, boerenworsten',
            price: '€ 12,50',
        },
    ]

    const menuSunday = [
        {
            title:
                'Creamy cauliflower soup',
            price: '€ 6,00',
        },
        {
            title:
                'Oven baked turkey, potatoes and carrots, cooking juice',
            price: '€ 12,50',
        },
    ]
    const menuSunday_fr = [
        {
            title:
                'Crème de chou-fleur',
            price: '€ 6,00',
        },
        {
            title:
                'Dinde au four, grenailles et carottes, jus de cuisson',
            price: '€ 12,50',
        },
    ]
    const menuSunday_nl = [
        {
            title:
                'Romige bloemkoolsoep',
            price: '€ 6,00',
        },
        {
            title:
                'Dinde au four, grenailles et carottes, jus de cuisson',
            price: '€ 12,50',
        },
    ]
    return (
        <>
            <div className='content_menu-items' id='t1'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Monday"}
                          {lang === 'fr' && "Lundi"}
                          {lang === 'nl' && "Maandag"}
                      </span>
                {lang === 'en' && menuMonday.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuMonday_fr.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuMonday_nl.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br/>
            <div className='content_menu-items' id='t2'>
                      <span className='content_menu-title'>
                          {lang === 'en' && "Dinsdag"}
                          {lang === 'fr' && "Mardi"}
                          {lang === 'nl' && "Tuesday"}
                      </span>
                {lang === 'en' && menuTuesday.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuTuesday_fr.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuTuesday_nl.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br/>
            <div className='content_menu-items' id='t3'>
                      <span className='content_menu-title not-padding'>
                          {lang === 'en' && "Wednesday"}
                          {lang === 'fr' && "Mercredi"}
                          {lang === 'nl' && "Woensdag"}
                      </span>
                {lang === 'en' && menuWednesday.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuWednesday_fr.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuWednesday_nl.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br/>
            <div className='content_menu-items' id='t4'>
                      <span className='content_menu-title not-padding'>
                          {lang === 'en' && "Thursday"}
                          {lang === 'fr' && "Jeudi"}
                          {lang === 'nl' && "Donderdag"}
                      </span>
                {lang === 'en' && menuThursday.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuThursday_fr.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuThursday_nl.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br/>
            <div className='content_menu-items' id='t5'>
                      <span className='content_menu-title not-padding'>
                          {lang === 'en' && "Friday"}
                          {lang === 'fr' && "Vendredi"}
                          {lang === 'nl' && "Vrijdag"}
                      </span>
                {lang === 'en' && menuFriday.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuFriday_fr.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuFriday_nl.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br/>
            <div className='content_menu-items' id='t6'>
                      <span className='content_menu-title not-padding'>
                          {lang === 'en' && "Saturday"}
                          {lang === 'fr' && "Samedi"}
                          {lang === 'nl' && "Zaterdag"}
                      </span>
                {lang === 'en' && menuSaturday.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuSaturday_fr.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuSaturday_nl.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br/>
            <div className='content_menu-items' id='t7'>
                      <span className='content_menu-title not-padding'>
                          {lang === 'en' && "Sunday"}
                          {lang === 'fr' && "Dimanche"}
                          {lang === 'nl' && "Zondag"}
                      </span>
                {lang === 'en' && menuSunday.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuSunday_fr.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuSunday_nl.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default MenuContentTodaySpecial;