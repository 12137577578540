import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Layout from '../../../layout/layout'
// import TabButtons from "../../boetfort/tabButtons";
// import {NavLink} from 'react-router-dom'
import MenuLeft from './menuLeft'
// import MenuGrill from './menuGrill'
import WindowWidth from '../../../WindowWidth'
// import { useTranslation } from 'react-i18next'
import MenuContent from "./menuContent";
// import MenuContentGrill from "./menuContentGrill";

const BoetfortMenu = () => {

  const [toggleState, setToggleState] = useState(1)
  let { scrollPosition } = WindowWidth()

  const toggleTab = (index) => {
    setToggleState(index)
    scrollPosition = 0
    console.log(toggleState)
  }

  const getActiveClass = (index, className) => (toggleState === index ? className : '')


  return (
    <>
      <Helmet>
        <meta
          name='description'
          content='Restaurants menu boetfort description'
          data-react-helmet='true'
        />
        <title>Restaurants boetfort</title>
      </Helmet>
      <Layout />
      <main className='main'>
        <div className='container'>
          <section className='restaurant-menu'>
            <div className='restaurant-menu__items'>
              <div className='container-tab'>
                <ul className={scrollPosition > 200 ? 'tab-list active-scroll' : 'tab-list'}>
                  <li
                    className={`tabs ${getActiveClass(1, 'active-tabs')}`}
                    onClick={() => toggleTab(1)}>
                    <span>menu</span>
                  </li>
                  {toggleState === 1 ? <MenuLeft /> : ''}

                  <li
                    className={`tabs ${getActiveClass(3, 'active-tabs')}`}
                    onClick={() => toggleTab(3)}>
                    <span>today's special</span>
                  </li>
                  <li
                    className={`tabs ${getActiveClass(4, 'active-tabs')}`}
                    onClick={() => toggleTab(4)}>
                    <span>suggestions</span>
                  </li>
                </ul>

                <div className='content-container'>
                  <div className={`content ${getActiveClass(1, 'active-content')}`}>
                    <MenuContent/>
                  </div>
                  <div className={`content ${getActiveClass(3, 'active-content')}`}>
                    {/*<div className="restaurant-menu__item">*/}
                    {/*<MenuLeft/>*/}
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. A accusamus alias
                      beatae delectus deleniti eos est eveniet inventore itaque iusto nulla odit
                      perspiciatis provident quaerat, similique, ullam velit. Adipisci amet
                      assumenda commodi cum delectus dicta dolorem eos, est fugiat illo ipsum iusto
                      laudantium magnam maxime minima natus non nostrum, odio officia qui quia quis
                      quos recusandae suscipit unde ut voluptatum? Consequuntur expedita hic in
                      molestiae numquam, officiis praesentium quaerat recusandae reiciendis
                      similique tenetur veritatis? Animi blanditiis consectetur consequatur cum
                      debitis dolor doloremque dolores, ex excepturi id incidunt ipsam ipsum iste,
                      itaque iure laudantium magnam minus nobis numquam officiis, pariatur quae
                      quibusdam repellat sapiente sed suscipit ullam ut vero voluptas voluptates. A,
                      adipisci alias aliquam aliquid animi assumenda beatae dolor error et,
                      excepturi explicabo facilis fugiat impedit ipsum optio praesentium quisquam
                      quod reprehenderit, saepe sit. Cupiditate eaque eos incidunt nihil quasi
                      reiciendis reprehenderit voluptatum. At culpa ducimus explicabo ipsum iusto
                      molestias omnis quam quasi repellat voluptatum. Alias dolore eos, harum illum
                      ipsum minima officiis quae? Dicta ipsa quia quidem. Debitis dicta neque odit
                      unde voluptate. At beatae dolores ex excepturi libero minus officiis, quidem,
                      quisquam quod sed tenetur ut voluptate, voluptatum! Distinctio ea error iure
                      obcaecati quaerat unde, voluptate. A accusamus aliquam aliquid aperiam
                      architecto, asperiores assumenda atque cum dolorem dolores eius est expedita
                      ipsam ipsum iusto libero modi nisi odio perferendis, provident quae quaerat
                      quasi quidem, quisquam quos ratione rem repellat reprehenderit repudiandae
                      tempora tempore vel vitae voluptatum? Consequuntur eveniet excepturi facere
                      facilis itaque nihil, numquam repudiandae suscipit tempore vero vitae
                      voluptatem voluptates! Cupiditate, doloremque eos labore odio officiis
                      reprehenderit! Alias aliquam aperiam aspernatur assumenda aut blanditiis
                      consectetur culpa cumque delectus dolore dolorem dolores ducimus eaque eos
                      esse exercitationem facere fuga hic id incidunt itaque modi nam natus nihil
                      non, praesentium provident quaerat qui quis ratione repellat rerum saepe,
                      temporibus unde veritatis vero vitae. Aut debitis error esse, id labore
                      laboriosam non praesentium provident qui quidem quis rerum sit vitae? Aliquam,
                      animi, blanditiis dolores eligendi est inventore ipsum, magnam nihil numquam
                      officiis perspiciatis provident quo. A corporis ducimus expedita hic inventore
                      iure quibusdam similique tempore tenetur voluptatibus? Autem illum maxime nisi
                      quasi repellendus similique soluta veritatis. Aliquam aperiam aspernatur
                      beatae dolor doloribus ducimus eaque earum eligendi eveniet expedita facere
                      iure iusto modi nam necessitatibus officiis omnis praesentium quia quidem quod
                      quos repellat saepe sequi, sint sit tempore vitae voluptate. Eius inventore
                      ipsa nisi odit provident. Ad at deserunt distinctio ea exercitationem maiores
                      nihil numquam suscipit vel voluptatem. A accusamus accusantium alias aliquid
                      commodi consectetur consequatur dolor dolorem doloremque et ex facere id iste,
                      itaque nisi obcaecati odio optio pariatur tempore veniam. Deserunt
                      exercitationem iste molestias porro provident qui? Assumenda culpa ducimus
                      explicabo fugiat magni nobis possimus quis vel! Alias aut doloremque fugiat
                      incidunt nam odio odit officia optio quas. At consectetur, ducimus impedit
                      necessitatibus nesciunt quisquam ratione reiciendis vero! Animi, architecto
                      commodi debitis distinctio, ea eius exercitationem facilis numquam obcaecati
                      soluta voluptas voluptate. Aperiam excepturi, ipsam minima nobis officiis
                      quaerat sunt voluptate. Cum ducimus est fugit rem repudiandae. Eligendi ex
                      excepturi tenetur voluptas voluptates. Deleniti eaque et laboriosam porro
                      praesentium quasi quos repellendus vero voluptates! Ad cum, cumque debitis
                      explicabo id, ipsum minus optio perferendis quia sequi tempora vel. Architecto
                      consectetur fuga id necessitatibus optio qui, rem voluptate voluptatibus!
                      Aliquid, autem, cumque deserunt earum eveniet fuga ipsam laudantium modi
                      molestiae molestias nam, nisi nobis officia perspiciatis provident quaerat
                      ratione voluptatibus! Accusantium exercitationem illo ipsam molestias natus
                      nisi officia, pariatur porro velit voluptatibus! Accusamus accusantium
                      asperiores beatae blanditiis dolorem facilis itaque iure magnam maiores,
                      neque, nobis quas reiciendis repellat repellendus reprehenderit sint sit
                      tempore voluptatum. Debitis in itaque iusto magni reprehenderit? A aut
                      blanditiis dicta ducimus, expedita explicabo facere libero natus nesciunt
                      nihil officiis quae rem, rerum tempore vitae! A amet asperiores culpa cumque
                      dicta eaque earum, eos eveniet id illo inventore ipsum itaque molestiae
                      molestias natus nesciunt possimus qui quia quod repellat sapiente tenetur unde
                      vel. Aperiam autem doloribus harum incidunt labore. Animi atque deleniti ea
                      eveniet hic illo maxime, nesciunt perspiciatis quis sunt! Adipisci aperiam
                      architecto debitis dolore doloribus dolorum eum explicabo fugiat impedit
                      laborum odio, odit placeat, quae quasi quibusdam quisquam quos similique
                      temporibus vitae voluptatibus? Ab adipisci aliquid atque, consectetur
                      consequatur dicta dignissimos ex explicabo natus voluptates. Architecto
                      consectetur, cupiditate debitis dicta ducimus ea eaque eum facilis modi
                      numquam odio officia ratione, veniam? A aliquam aliquid aspernatur at
                      consequatur corporis cumque debitis dolor esse eum ipsa ipsum itaque labore
                      minima molestias nostrum numquam omnis porro, quas qui quis repellendus
                      repudiandae saepe sint tempore totam vero vitae voluptate voluptatem
                      voluptates. A accusamus animi consequuntur deleniti dolore eaque eligendi
                      error eveniet fuga fugit id labore magnam magni minus, nemo non optio qui
                      quibusdam repellendus reprehenderit temporibus, totam voluptates! Aliquid
                      aperiam atque, dolore eius facere illo illum incidunt inventore iure iusto
                      laborum nobis, pariatur quas quod voluptatum! Debitis eaque eius enim laborum
                      non nulla officia quam quos tenetur. Consectetur consequuntur, cupiditate
                      delectus dicta dolor doloribus error impedit laboriosam minus molestias neque,
                      perferendis perspiciatis quasi quo recusandae rem sequi suscipit, tempora vel
                      veniam! Accusamus alias assumenda ipsum recusandae sequi? Aliquid, aspernatur
                      at cumque error expedita fugiat impedit iusto mollitia natus nemo neque odio
                      odit perspiciatis quae reiciendis soluta suscipit voluptatum! Ad aperiam,
                      eligendi et maxime obcaecati reiciendis ut? A accusantium aliquam beatae dicta
                      eligendi eos error ipsum iure minima mollitia nemo nostrum placeat quas quod
                      quos ratione recusandae rem, repellendus soluta ut. Aspernatur at doloribus
                      eaque, eum fuga ipsam laboriosam molestiae natus officiis quaerat ratione
                      recusandae sed similique sint tempora ut veritatis vitae. Cupiditate dolor
                      exercitationem iste quae voluptate. At culpa eos eveniet exercitationem maxime
                      odio odit optio perspiciatis quia recusandae. Ab consectetur dolorem dolorum
                      enim itaque quo rerum saepe! Accusamus aliquam amet architecto aut autem
                      dolores est facere facilis fugiat incidunt ipsam maiores non nulla porro
                      praesentium provident rem reprehenderit rerum sed sint suscipit tempora
                      tenetur, vel vero voluptatem. Dolorem eum quam quos sequi sint. A accusantium
                      architecto corporis cupiditate dicta distinctio est eum fugit modi nemo
                      possimus quas quisquam, repellendus sapiente suscipit temporibus velit
                      voluptas voluptatum. Molestiae necessitatibus, soluta.
                    </p>
                    {/*</div>*/}
                  </div>

                  <div className={`content ${getActiveClass(4, 'active-content')}`}>
                    {/*<div className="restaurant-menu__item">*/}
                    {/*<MenuLeft/>*/}
                    <p>
                      A accusamus alias beatae delectus deleniti eos est eveniet inventore itaque
                      iusto nulla odit perspiciatis provident quaerat, similique, ullam velit.
                      Adipisci amet assumenda commodi cum delectus dicta dolorem eos, est fugiat
                      illo ipsum iusto laudantium magnam maxime minima natus non nostrum, odio
                      officia qui quia quis quos recusandae suscipit unde ut voluptatum?
                      Consequuntur expedita hic in molestiae numquam, officiis praesentium quaerat
                      recusandae reiciendis similique tenetur veritatis? Animi blanditiis
                      consectetur consequatur cum debitis dolor doloremque dolores, ex excepturi id
                    </p>
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  )
}

export default BoetfortMenu
