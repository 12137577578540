import React from 'react';
import { useTranslation } from 'react-i18next'

const MenuContentSuggestion = () => {
    const {i18n } = useTranslation()
    let lang = i18n.language

    const menuSuggestions = [
        {
            title:
                'Scampi skewers, farmers noodles and wok vegetables',
            price: '€ 23,00',
        },
        {
            title:
                'Beef burger with mozzarella, local ham, sun dried tomatoes, rocket and creamy pesto',
            price: '€ 19,99',
        },
        {
            title:
                'Hunter\'s Burger (minced deer steak, pear in wine, smoked bacon, \n' +
                'pointed cabbage, crémeux with cranberries) and fries',
            price: '€ 23,00',
        },
        {
            title:
                'Crispy Chicken burger, sweet chilli sauce, roasted peppers, salad, fries',
            price: '€ 18,99',
        },
        {
            title:
                'Veggie burger- Buckwheat steak, salad, avocado, beetroot mousse, and cheddar',
            price: '€ 18,99',
        },
        {
            title:
                'Mezze Royal (hummus, tarama, eggplant mousse, tzatziki, scampis, grilled chicken breast)',
            price: '€ 25,00',
        },
        {
            title:
                'Crispy fish burger and shrimp solitaire (brill fillet in panko breadcrumbs, cheddar, salad, \n' +
                'fried king prawns and creamy shrimp bisque) fries',
            price: '€ 23,00',
        },
        {
            title:
                'Deer crown, baked root vegetables, pear in wine, pepper sauce',
            price: '€ 26,00',
        },
        {
            title:
                'Wild boar goulash, cranberry pear, oven baked root vegetables',
            price: '€ 23,00',
        },
        {
            title:
                'White chocolate cheesecake, mixed caramelized nuts and exotic coulis',
            price: '€ 9,00',
        },
    ]
    const menuSuggestions_fr = [
        {
            title:
                'Brochettes de scampis, nouilles campagnardes et légumes façon wok',
            price: '€ 23,00',
        },
        {
            title:
                'Burger de boeuf à la mozzarella, jambon du pays, tomates séchées, roquette et \n' +
                'pesto crémeux',
            price: '€ 19,99',
        },
        {
            title:
                'Burger du Chasseur (steak haché de biche, poire au vin, lard fumé,\n' +
                'choux pointu, crémeux aux airelles) et frites',
            price: '€ 23,00',
        },
        {
            title:
                'Crispy Chicken burger, sweet chilli sauce, poivrons rôtis, salade, frites',
            price: '€ 18,99',
        },
        {
            title:
                'Veggie burger- Steak de sarrasin, salade, avocat, mousse de betterave et cheddar',
            price: '€ 18,99',
        },
        {
            title:
                'Mezze Royal (houmous, tarama, mousse d’aubergines, tzaziki, scampis, blanc de poulet grillé)',
            price: '€ 25,00',
        },
        {
            title:
                'Crispy fish burger et son solitaire de gambas (filet de barbue pané au panko, cheddar, \n' +
                'salade, gambas poêlée et crémeux de bisque de crevettes) frites',
            price: '€ 23,00',
        },
        {
            title:
                'Couronne de biche, légumes racines aux four, poire au vin,sauce poivrade',
            price: '€ 26,00',
        },
        {
            title:
                'Couronne de biche, légumes racines aux four, poire au vin,sauce poivrade',
            price: '€ 23,00',
        },
        {
            title:
                'Cheesecake au chocolat blanc, mixte de noix caramélisées et coulis exotique',
            price: '€ 9,00',
        },
    ]
    const menuSuggestions_nl = [
        {
            title:
                'Scampibrochette, boeren noedels en wokgroenten',
            price: '€ 23,00',
        },
        {
            title:
                'Rundsburger met mozzarella, lokale ham, rucola, zongedroogde tomaten en \n' +
                'romige pesto',
            price: '€ 19,99',
        },
        {
            title:
                'Jagersburger (hertengehakt, peer in wijn, gerookt spek, spitskool, \n' +
                'crémeux met veenbessen) en frietjes',
            price: '€ 23,00',
        },
        {
            title:
                'Krokante kipburger, zoete chilisaus, geroosterde paprika, salade, frietjes',
            price: '€ 18,99',
        },
        {
            title:
                'Veggie burger-Boekweitsteak,salade,avocado, rode bietenmousse en cheddar ',
            price: '€ 18,99',
        },
        {
            title:
                'Mezze Royal (hummus, tarama, auberginemousse, tzatziki, scampi\'s, gegrilde kipfilet)',
            price: '€ 25,00',
        },
        {
            title:
                'Krokante visburger en garnaal solitaire (grietfilet in panko paneermeel, cheddar, \n' +
                'salade, gebakken gamba\'s en romige garnalenbisque) frietjes',
            price: '€ 23,00',
        },
        {
            title:
                'Hindekroon, gebakken wortelgroenten, peer in wijn, pepersaus',
            price: '€ 26,00',
        },
        {
            title:
                'Wild zwijn goulash, veenbessen peer, oven gebakken wortelgroenten',
            price: '€ 23,00',
        },
        {
            title:
                'Witte chocolade cheesecake, gemengde gekarameliseerde noten en \n' +
                'exotische coulis',
            price: '€ 9,00',
        },
    ]



    return (
        <>
            <div className='content_menu-items' id='s1'>
                      {/*<span className='content_menu-title'>*/}
                      {/*  {lang === 'en' && "Snacks / Starters"}*/}
                      {/*    {lang === 'fr' && "Snacks / Entrées"}*/}
                      {/*    {lang === 'nl' && "Snacks / Voorgerechten"}*/}
                      {/*</span>*/}
                {lang === 'en' && menuSuggestions.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuSuggestions_fr.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuSuggestions_nl.map((item) => (
                    <div key={item.title} className='content_menu-item not-padding'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default MenuContentSuggestion;