import { THEME_DARK, THEME_LIGHT } from "./type";

const initialState = {
    isDark: false,
};

const reducerTheme = (state = initialState, action: { type: any; }) => {
    switch (action.type) {
        case THEME_DARK:
            return { isDark: true };
        case THEME_LIGHT:
            return { isDark: false };
        default:
            return state;
    }
};

export default reducerTheme;