import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Layout from '../../../layout/layout'
// import TabButtons from "../../boetfort/tabButtons";
// import {NavLink} from 'react-router-dom'
import MenuLeft from './menuLeft'
import MenuGrill from './menuGrill'
import WindowWidth from '../../../WindowWidth'
import { useTranslation } from 'react-i18next'
import MenuContent from "./menuContent";
import MenuContentGrill from "./menuContentGrill";
import MenuContentSuggestion from "./menuContentSuggestion";
import MenuContentTodaySpecial from "./menuContentTodaySpecial";

const GrimbergenMenu = () => {
  const {t} = useTranslation()

  const [toggleState, setToggleState] = useState(1)
  let { scrollPosition } = WindowWidth()

  const toggleTab = (index) => {
    setToggleState(index)
    scrollPosition = 0
    console.log(toggleState)
  }

  const getActiveClass = (index, className) => (toggleState === index ? className : '')


  return (
    <>
      <Helmet>
        <meta
          name='description'
          content='Restaurants menu grimbergen description'
          data-react-helmet='true'
        />
        <title>grimbergen restaurant menu</title>
      </Helmet>
      <Layout />
      <main className='main'>
        <div className='container'>
          <section className='restaurant-menu'>
            <div className='restaurant-menu__items'>
              <div className='container-tab'>
                <ul className={scrollPosition > 200 ? 'tab-list active-scroll' : 'tab-list'}>
                  <li
                    className={`tabs ${getActiveClass(1, 'active-tabs')}`}
                    onClick={() => toggleTab(1)}>
                    <span>{t('menu.menu')}</span>
                  </li>
                  {toggleState === 1 ? <MenuLeft /> : ''}

                  <li
                      className={`tabs ${getActiveClass(2, 'active-tabs')}`}
                      onClick={() => toggleTab(2)}>
                    <span>{t('menu.grill')}</span>
                  </li>
                  {toggleState === 2 ? <MenuGrill /> : ''}

                  <li
                    className={`tabs ${getActiveClass(3, 'active-tabs')}`}
                    onClick={() => toggleTab(3)}>
                    <span>{t('menu.special')}</span>
                  </li>
                  <li
                    className={`tabs ${getActiveClass(4, 'active-tabs')}`}
                    onClick={() => toggleTab(4)}>
                    <span>{t('menu.suggestions')}</span>
                  </li>
                </ul>

                <div className='content-container'>
                  <div className={`content ${getActiveClass(1, 'active-content')}`}>
                    <MenuContent/>
                  </div>
                  <div className={`content ${getActiveClass(2, 'active-content')}`}>
                    <MenuContentGrill/>
                  </div>
                  <div className={`content ${getActiveClass(3, 'active-content')}`}>
                    <MenuContentTodaySpecial/>
                  </div>
                  <div className={`content ${getActiveClass(4, 'active-content')}`}>
                    <MenuContentSuggestion/>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  )
}

export default GrimbergenMenu
