import React from 'react'
import Contact from './components/pages/contact'
import { Route, Switch } from 'react-router-dom'
import Home from './components/pages/home'
import NotFoundPage from './components/pages/NotFoundPage'
// import Grimbergen from './components/pages/grimbergen/grimbergen'
// import Boetfort from './components/pages/boetfort/boetfort'
import BoetfortMenu from "./components/pages/menu/buetfort/boetfortMenu";
import GrimbergenMenu from "./components/pages/menu/grimbergen/grimbergenMenu";

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        {/*<Route exact path='/grimbergen'>*/}
        {/*  <Grimbergen />*/}
        {/*</Route>*/}
        {/*<Route exact path='/boetfort'>*/}
        {/*  <Boetfort />*/}
        {/*</Route>*/}
        <Route exact path='/boetfort-menu'>
          <BoetfortMenu />
        </Route>
        <Route exact path='/grimbergen-menu'>
          <GrimbergenMenu />
        </Route>
        <Route exact path='/contact'>
          <Contact />
        </Route>
        <Route path='*' component={NotFoundPage} />
      </Switch>
    </>
  )
}

export default App
