import React from 'react';
import Layout from "../layout/layout";
import {NavLink} from "react-router-dom";

const NotFoundPage = () => {
    return (
        <>
            <Layout/>

            <main className="main">
                <div className="container__wrapper">

                    <div className="container">
                        {/*<img src={PageNotFound}  />*/}
                        <p style={{textAlign:"center"}}>Page not found!</p>
                        <p style={{textAlign:"center"}}>
                            <NavLink to="/">Go to Home </NavLink>
                        </p>
                    </div>
                </div>
            </main>
        </>
    );
};

export default NotFoundPage;