import React from 'react';
import { useTranslation } from 'react-i18next'

const MenuContentGrill = () => {
    const {i18n } = useTranslation()
    let lang = i18n.language

    const menuStarters = [
        {

            title: 'Portion of olives',
            price: '€ 4,50',
        },
        {

            title:
                'Portie kaas of salami',
            price: '€ 5,50 ',
        },
        {

            title:
                'Mixed portion of salami and cheese',
            price: '€ 6,00',
        },
        {

            title: 'Mixed portion of salami, cheese and olives',
            price: '€ 7,00',
        },
        {

            title: 'Soup of the day with bread',
            price: '€ 6,00',
        },
        {

            title:
                'Cheese croquette (homemade) 1 piece / 2 pieces',
            price: '€ 7,50 / € 13,50',
        },
        {

            title: 'Shrimp croquettes (homemade) 1 piece / 2 pieces',
            price: '€ 9,00 / € 15,50',
        },
        {

            title:
                'Mix duo of cheese and shrimp croquettes (homemade) ',
            price: '€ 14,50',
        },
        {

            title:
                'Croque Monsieur',
            price: '€ 9,00',
        },
        {

            title:
                'Croque Madame',
            price: '€ 9,50',
        },
        {

            title: 'Calamares à la Romana',
            price: '€ 10,50',
        },
        {

            title:
                'Toast with avocado and candied tomatoes',
            price: '€ 13,50',
        },
        {

            title: 'Toast with avocado and smoked salmon',
            price: '€ 15,00',
        },
    ]
    const menuStarters_fr = [
        {

            title: 'Portion d‘olives',
            price: '€ 4,50',
        },
        {

            title:
                'Portion cheese or salami',
            price: '€ 5,50 ',
        },
        {

            title:
                'Portion mixte salami et fromage',
            price: '€ 6,00',
        },
        {

            title: 'Portion mixte salami, fromage et olives',
            price: '€ 7,00',
        },
        {

            title: 'Soupe du jour avec du pain',
            price: '€ 6,00',
        },
        {

            title:
                'Croquette de fromage (faite maison) 1 pièce / 2 pièces',
            price: '€ 7,50 / € 13,50',
        },
        {

            title: 'Croquette de crevette (faite maison) 1 pièce / 2 pièces',
            price: '€ 9,00 / € 15,50',
        },
        {

            title:
                'Mix duo de croquette au fromage et crevette (faites maison)',
            price: '€ 14,50',
        },
        {

            title:
                'Croque Monsieur',
            price: '€ 9,00',
        },
        {

            title:
                'Croque Madame',
            price: '€ 9,50',
        },
        {

            title: 'Calamares à la Romana',
            price: '€ 10,50',
        },
        {

            title:
                'Toast à l‘avocat et tomates confites',
            price: '€ 13,50',
        },
        {

            title: 'Toast à l‘avocat et saumon fumé',
            price: '€ 15,00',
        },
    ]
    const menuStarters_nl = [
        {

            title: 'Portie olijven',
            price: '€ 4,50',
        },
        {

            title:
                'Portie kaas of salami',
            price: '€ 5,50 ',
        },
        {

            title:
                'Portie gemengd salami en kaas',
            price: '€ 6,00',
        },
        {

            title: 'Portie gemengd salami, kaas en olijven',
            price: '€ 7,00',
        },
        {

            title: 'Dagsoep met brood',
            price: '€ 6,00',
        },
        {

            title:
                'Kaaskroket (huisgemaakt) 1 stuk / 2 stukken',
            price: '€ 7,50 / € 13,50',
        },
        {

            title: 'Garnaalkroket (huisgemaakt) 1 stuk / 2 stukken',
            price: '€ 9,00 / € 15,50',
        },
        {

            title:
                'Mix duo van kaas- en garnaalkroket (huisgemaakt) ',
            price: '€ 14,50',
        },
        {

            title:
                'Croque Monsieur',
            price: '€ 9,00',
        },
        {

            title:
                'Croque Madame',
            price: '€ 9,50',
        },
        {

            title: 'Calamares à la Romana',
            price: '€ 10,50',
        },
        {

            title:
                'Toast met avocado en gekonfijte tomaten',
            price: '€ 13,50',
        },
        {

            title: 'Toast met avocado en gerookte zalm',
            price: '€ 15,00',
        },
    ]

    const menuSalads = [
        {
            title:
                'Salad with goat cheese, crispy bacon and roasted apples',
            price: '€ 16,90',
        },
        {
            title:
                'Salad with bacon, grilled chicken tenderloins, poached egg, croutons \n' +
                'and new potatoes',
            price: '€ 16,90',
        },
        {
            title:
                'Salad with smoked salmon, chicory and citrus fruits',
            price: '€ 16,90',
        },
        {
            title:
                'Warm mixed vegetable salad, feta and balsamic and basil vinaigrette',
            price: '€ 16,90',
        },
    ]
    const menuSalads_fr = [
        {
            title:
                'Salade de chèvre, croustillants de lard et pommes rôties',
            price: '€ 16,90',
        },
        {
            title:
                'Salade aux lardons, aiguillettes de poulet grillées, œuf poché , croûtons \n' +
                'et pommes grenailles',
            price: '€ 16,90',
        },
        {
            title:
                'Salade aux saumons fumé, chicons et agrumes',
            price: '€ 16,90',
        },
        {
            title:
                'Salade de légumes mixtes chaude, fêta et vinaigrette balsamique et \n' +
                'basilic',
            price: '€ 16,90',
        },
    ]
    const menuSalads_nl = [
        {
            title:
                'Salade met geitenkaas, krokante bacon en geroosterde appels',
            price: '€ 16,90',
        },
        {
            title:
                'Salade met spekjes, gegrilde kippenhaasjes, gepocheerd ei, \n' +
                'croutons en krieltjes',
            price: '€ 16,90',
        },
        {
            title:
                'Salade met gerookte zalm, witlof en citrusvruchten',
            price: '€ 16,90',
        },
        {
            title:
                'Warme gemengde groentensalade, fetakaas en balsamico en \n' +
                'basilicumvinaigrette',
            price: '€ 16,90',
        },
    ]

    const menuPasta = [
        {
            title:
                'Spaghetti bolognaise',
            price: '€ 10,50',
        },
        {
            title: 'Lasagne bolognaise',
            price: '€ 13,50',
        },
        {
            title:
                'Pasta with vegetables, avocadopesto and cherry tomatoes',
            price: '€ 13,50',
        },

    ]
    const menuPasta_fr = [
        {
            title:
                'Spaghetti bolognaise',
            price: '€ 10,50',
        },
        {
            title: 'Lasagne bolognaise',
            price: '€ 13,50',
        },
        {
            title:
                'Pâtes aux légumes, pesto d‘avocat et tomates cerises',
            price: '€ 13,50',
        },

    ]
    const menuPasta_nl = [
        {
            title:
                'Spaghetti bolognaise',
            price: '€ 10,50',
        },
        {
            title: 'Lasagne bolognaise',
            price: '€ 13,50',
        },
        {
            title:
                'Pasta met groenten, avocadopesto en cherrytomaten',
            price: '€ 13,50',
        },

    ]

    const menuFish = [
        {
            title:
                'Grilled salmon steak, mixed vegetables, bearnaise sauce and\n' +
                'side dish at choice',
            price: '€ 22,50',
        },
    ]
    const menuFish_fr = [
        {
            title:
                'Pavé de saumon grillé aux légumes mixtes,\n' +
                'sauce béarnaise et garniture au choix',
            price: '€ 22,50',
        },
    ]
    const menuFish_nl = [
        {
            title:
                'Gegrilde zalmsteak, gemengde groenten, ' +
                'bearnaisesaus, bijgerecht naar keuze',
            price: '€ 22,50',
        },
    ]

    const menuMeat = [
        {
            title:
                'Meatballs (beef) with a “melting heart“ and choice of side dish',
            price: '€ 14,75',
        },
        {
            title:
                'Meatballs (beef) with smoked bacon and choice of side dish',
            price: '€ 14,75',
        },
        {
            title:
                'Grilled beef steak, salad, sauce and side dish at choice',
            price: '€ 20,50',
        },
        {
            title:
                'Liège style beef stew with side dish at choice',
            price: '€ 18,70',
        },
        {
            title:
                'Beef burger with French fries',
            price: '€ 16,50',
        },
    ]
    const menuMeat_fr = [
        {
            title:
                'Boulettes de boeuf au “coeur fondant“ avec garniture au choix',
            price: '€ 14,75',
        },
        {
            title:
                'Boulettes de boeuf en robe de lard fumé avec garniture au choix',
            price: '€ 14,75',
        },
        {
            title:
                'Steak de boeuf grillé, salade, sauce et garniture au choix',
            price: '€ 20,50',
        },
        {
            title:
                'Carbonade de boeuf façon liégeoise avec garniture au choix',
            price: '€ 18,70',
        },
        {
            title:
                'Burger de boeuf avec frites',
            price: '€ 16,50',
        },
    ]
    const menuMeat_nl = [
        {
            title:
                'Gehaktballetjes (rund) met “smeltend“ hart en keuze bijgerecht',
            price: '€ 14,75',
        },
        {
            title:
                'Gehaktballetjes (rund) met gerookt spek en keuze bijgerecht',
            price: '€ 14,75',
        },
        {
            title:
                'Gegrilde rundssteak, salade, saus en bijgerecht naar keuze',
            price: '€ 20,50',
        },
        {
            title:
                'Runderstoofpot op Luikse wijze met bijgerecht naar keuze',
            price: '€ 18,70',
        },
        {
            title:
                'Rundsburger met frietjes',
            price: '€ 16,50',
        },
    ]

    const menuDesserts = [
        {
            title:
                'Apple pie, apple sauce, caramel sauce, vanilla ice cream',
            price: '€ 7,50',
        },
        {
            title: 'Chocolate mousse',
            price: '€ 6,00',
        },
        {
            title:
                'Moelleux au chocolat',
            price: '€ 8,00',
        },
        {
            title:
                'Dame Blanche',
            price: '€ 6,00',
        },
        {
            title:
                'Dame Noire',
            price: '€ 6,00',
        },
        {
            title:
                'Coupe Brésilienne',
            price: '€ 8,00',
        },
    ]
    const menuDesserts_fr = [
        {
            title:
                'Tarte aux pommes, compote de pommes, sauce caramel et sa glace de vanille',
            price: '€ 7,50',
        },
        {
            title: 'Mousse au chocolat',
            price: '€ 6,00',
        },
        {
            title:
                'Moelleux au chocolat',
            price: '€ 8,00',
        },
        {
            title:
                'Dame Blanche',
            price: '€ 6,00',
        },
        {
            title:
                'Dame Noire',
            price: '€ 6,00',
        },
        {
            title:
                'Coupe Brésilienne',
            price: '€ 8,00',
        },
    ]
    const menuDesserts_nl = [
        {
            title:
                'Appeltaart met appelmoes, karamelsaus en vanille-ijs',
            price: '€ 7,50',
        },
        {
            title: 'Chocolademousse',
            price: '€ 6,00',
        },
        {
            title:
                'Moelleux au chocolat',
            price: '€ 8,00',
        },
        {
            title:
                'Dame Blanche',
            price: '€ 6,00',
        },
        {
            title:
                'Dame Noire',
            price: '€ 6,00',
        },
        {
            title:
                'Coupe Brésilienne',
            price: '€ 8,00',
        },
    ]

    const menuPancakes = [
        {
            title:
                'Pancakes (2 pieces) with sugar',
            price: '€ 6,00',
        },
        {
            title:
                'Pancakes (2 pieces) with jam',
            price: '€ 6,00',
        },
        {
            title:
                'Mikado. \n' +
                '2 pieces with ice cream and chocolate sauce',
            price: '€ 7,00',
        },
    ]
    const menuPancakes_fr = [
        {
            title:
                'Crêpes (2 pièces) au sucre',
            price: '€ 6,00',
        },
        {
            title:
                'Crêpes (2 pièces) à la confiture',
            price: '€ 6,00',
        },
        {
            title:
                'Mikado. \n' +
                '2 pièces avec de la glace et sauce au chocolat\n',
            price: '€ 7,00',
        },
    ]
    const menuPancakes_nl = [
        {
            title:
                'Pannenkoeken (2 stuks) met suiker. \n',
            price: '€ 6,00',
        },
        {
            title:
                'Pannenkoeken (2 stuks) met confituur. \n',
            price: '€ 6,00',
        },
        {
            title:
                'Mikado. \n' +
                ' - 2 stuks met ijs en chocoladesaus\n',
            price: '€ 7,00',
        },
    ]

    const menuMilkshakes = [
        {
            title: 'Vanilla',
            price: '€ 6,00',
        },

        {
            title: 'Mocha',
            price: '€ 6,00',
        },

        {
            title: 'Chocolate',
            price: '€ 6,00',
        },

        {
            title: 'Strawberries',
            price: '€ 6,00',
        },

        {
            title: 'Raspberries',
            price: '€ 6,00',
        },

        {
            title: 'Coconut',
            price: '€ 6,00',
        },

        {
            title: 'Banana',
            price: '€ 6,00',
        },
    ]
    const menuMilkshakes_fr = [
        {
            title: 'Vanille',
            price: '€ 6,00',
        },

        {
            title: 'Moka',
            price: '€ 6,00',
        },

        {
            title: 'Chocolat',
            price: '€ 6,00',
        },

        {
            title: 'Fraises',
            price: '€ 6,00',
        },

        {
            title: 'Framboises',
            price: '€ 6,00',
        },

        {
            title: 'Coco',
            price: '€ 6,00',
        },

        {
            title: 'Banane',
            price: '€ 6,00',
        },
    ]
    const menuMilkshakes_nl = [
        {
            title: 'Vanille',
            price: '€ 6,00',
        },

        {
            title: 'Mokka',
            price: '€ 6,00',
        },

        {
            title: 'Chocolade',
            price: '€ 6,00',
        },

        {
            title: 'Aardbeien',
            price: '€ 6,00',
        },

        {
            title: 'Frambozen',
            price: '€ 6,00',
        },

        {
            title: 'Kokos',
            price: '€ 6,00',
        },

        {
            title: 'Banaan',
            price: '€ 6,00',
        },
    ]

    const menuWater = [
        {
            title: 'Still water Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'Still water Evian',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
        {
            title: 'Sparkling water Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'Sparkling water San Pellegrino',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
    ]
    const menuWater_fr = [
        {
            title: 'Eau plate Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'Eau plate Evian',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
        {
            title: 'Eau pétillante Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'Eau pétillante San Pellegrino',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
    ]
    const menuWater_nl = [
        {
            title: 'Plat water Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'Plat water Evian',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
        {
            title: 'Spuitwater Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'Spuitwater San Pellegrino',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
    ]

    const menuSoftDrinks = [
        {
            title: 'Coca Cola / Zero / Light / Fanta / Sprite',
            price: '20 cl € 2,50',
        },
        {
            title: 'Schweppes Tonic / Agrumes',
            price: '25 cl € 2,60',
        },
        {
            title: 'Canada Dry',
            price: '20 cl € 2,60',
        },
        {
            title: 'Cécémel',
            price: '20 cl € 2,60',
        },
        {
            title: 'Tönissteiner',
            price: '25 cl € 2,75',
        },
        {
            title: 'Aquarius Lemon / Orange / Red peach',
            price: '33 cl € 2,85',
        },
        {
            title: 'Tonic Fever Tree',
            price: '20 cl € 3,60',
        },
    ]

    const menuFruitJuices = [
        {
            title: 'Orange juice',
            price: '20 cl € 2,50',
        },
        {
            title: 'Pineapple juice',
            price: '20 cl € 2,50',
        },
        {
            title: ' Apple juice',
            price: '20 cl € 2,50',
        },
        {
            title: 'Grapefruit juice',
            price: '20 cl € 2,50',
        },
        {
            title: 'Tomato juice',
            price: '20 cl € 2,50',
        },
        {
            title: 'Looza ACE',
            price: '20 cl € 2,50',
        },
    ]
    const menuFruitJuices_fr = [
        {
            title: 'Jus d‘orange',
            price: '20 cl € 2,50',
        },
        {
            title: 'Jus d‘ananas ',
            price: '20 cl € 2,50',
        },
        {
            title: 'Jus de pomme',
            price: '20 cl € 2,50',
        },
        {
            title: 'Jus de pamplemousse',
            price: '20 cl € 2,50',
        },
        {
            title: 'Jus de tomates',
            price: '20 cl € 2,50',
        },
        {
            title: 'Jus d’orange',
            price: '20 cl € 4,25',
        },
        {
            title: 'Looza ACE',
            price: '20 cl € 2,50',
        },
    ]
    const menuFruitJuices_nl = [
        {
            title: 'Sinaasappelsap',
            price: '20 cl € 2,50',
        },
        {
            title: 'Ananassap',
            price: '20 cl € 2,50',
        },
        {
            title: 'Appelsap',
            price: '20 cl € 2,50',
        },
        {
            title: 'Pompelmoessap',
            price: '20 cl € 2,50',
        },
        {
            title: 'Tomatensap',
            price: '20 cl € 2,50',
        },
        {
            title: 'Looza ACE',
            price: '20 cl € 2,50',
        },
    ]

    const menuFresh = [
        {
            title: 'Orange juice',
            price: '25 cl € 4,25',
        },
        {
            title: 'Grapefruit juice',
            price: '25 cl € 4,25',
        },
        {
            title: 'Fruitcocktail',
            price: '25 cl € 4,25',
        },
        {
            title: 'Lemon juice (+ water and sugar)',
            price: '25 cl € 4,50',
        },
    ]
    const menuFresh_fr = [
        {
            title: 'Jus d’orange',
            price: '25 cl € 4,25',
        },
        {
            title: 'Jus de pamplemousse',
            price: '25 cl € 4,25',
        },
        {
            title: 'Cocktail de jus de fruits',
            price: '25 cl € 4,25',
        },
        {
            title: 'Jus de citron (+ eau et sucre)',
            price: '25 cl € 4,50',
        },
    ]
    const menuFresh_nl = [
        {
            title: 'Sinaasappelsap',
            price: '25 cl € 4,25',
        },
        {
            title: 'Pompelmoessap',
            price: '25 cl € 4,25',
        },
        {
            title: 'Fruitcocktail',
            price: '25 cl € 4,25',
        },
        {
            title: 'Citroensap (+ water en suiker)',
            price: '25 cl € 4,50',
        },
    ]

    const menuHotDrinks = [
        {
            title: 'Coffee / Espresso / Décaféiné',
            price: '€ 2,50',
        },
        {
            title: 'Tea',
            price: '€ 2,50',
        },
        {
            title: 'Coffee with hot milk',
            price: '€ 2,85',
        },
        {
            title: 'Whipped cream',
            price: '€ 2,85',
        },
        {
            title: 'Milk froth',
            price: '€ 2,85',
        },
        {
            title: 'Hot chocolat milk',
            price: '€ 2,85',
        },
        {
            title: 'Irish / Italian / French Coffee',
            price: '€ 6,50',
        },
    ]
    const menuHotDrinks_fr = [
        {
            title: 'Café / Espresso / Décaféiné',
            price: '€ 2,50',
        },
        {
            title: 'Thé',
            price: '€ 2,50',
        },
        {
            title: 'Lait russe',
            price: '€ 2,85',
        },
        {
            title: 'Crème fraîche',
            price: '€ 2,85',
        },
        {
            title: 'Mousse au lait',
            price: '€ 2,85',
        },
        {
            title: 'Chocolat chaud',
            price: '€ 2,85',
        },
        {
            title: 'Irish / Italian / French Coffee',
            price: '€ 6,50',
        },
    ]
    const menuHotDrinks_nl = [
        {
            title: 'Koffie / Espresso / Décaféiné',
            price: '€ 2,50',
        },
        {
            title: 'Thee',
            price: '€ 2,50',
        },
        {
            title: 'Koffie verkeerd',
            price: '€ 2,85',
        },
        {
            title: 'Cappuccino slagroom',
            price: '€ 2,85',
        },
        {
            title: 'Cappuccino melkschuim',
            price: '€ 2,85',
        },
        {
            title: 'Warme chocomelk',
            price: '€ 2,85',
        },
        {
            title: 'Irish / Italian / French Coffee',
            price: '€ 6,50',
        },
    ]

    const menuSpecialTea = [
        {
            title: 'Tisane d’assouan / Hermès / Kyoto',
            price: '€ 3,85',
        },
        {
            title: 'Aan de oever van de Nijl',
            price: '€ 3,85',
        },
        {
            title: 'Fresh mint tea with honey',
            price: '€ 3,50',
        },
        {
            title: 'Pure green tea',
            price: '€ 3,00',
        },
    ]
    const menuSpecialTea_fr = [
        {
            title: 'Tisane d’assouan / Hermès / Kyoto',
            price: '€ 3,85',
        },
        {
            title: 'Aan de oever van de Nijl',
            price: '€ 3,85',
        },
        {
            title: 'Thé à la menthe fraîche au miel',
            price: '€ 3,50',
        },
        {
            title: 'Thé vert pur',
            price: '€ 3,00',
        },
    ]
    const menuSpecialTea_nl = [
        {
            title: 'Tisane d’assouan / Hermès / Kyoto',
            price: '€ 3,85',
        },
        {
            title: 'Aan de oever van de Nijl',
            price: '€ 3,85',
        },
        {
            title: 'Verse muntthee met honing',
            price: '€ 3,50',
        },
        {
            title: 'Pure groene thee',
            price: '€ 3,00',
        },
    ]

    const menuMocktails = [
        {
            title: 'Ice tea Peach, Mango, Banana, Pineapple, Cucumber, Grapefruit',
            price: '€ 3,75',
        },
        {
            title: 'Lemonade',
            price: '€ 3,75',
        },
        {
            title: 'Strawberry / Rose',
            price: '€ 3,95',
        },
        {
            title: 'Watermelon',
            price: '€ 3,95',
        },
        {
            title: 'Ginger',
            price: '€ 4,25',
        },
        {
            title: 'Chinees',
            price: '€ 4,50',
        },
        {
            title: 'Acapulco',
            price: '€ 4,60',
        },
        {
            title: 'Virgin Mojito',
            price: '€ 6,00',
        },
        {
            title: 'Virgin Mojito Strawberry / Passion Fruit Mango / Raspberries / Kiwi',
            price: '€ 6,50',
        },
    ]
    const menuMocktails_fr = [
        {
            title: 'Ice tea Pêche, Mangue, Banane, Ananas, Concombre, Pamplemousse',
            price: '€ 3,75',
        },
        {
            title: 'Limonade',
            price: '€ 3,75',
        },
        {
            title: 'Fraise / Rozen',
            price: '€ 3,95',
        },
        {
            title: 'Pastèque',
            price: '€ 3,95',
        },
        {
            title: 'Gingembre',
            price: '€ 4,25',
        },
        {
            title: 'Eskimo',
            price: '€ 4,50',
        },
        {
            title: 'Applemint Smash',
            price: '€ 4,60',
        },
        {
            title: 'Virgin Mojito',
            price: '€ 6,00',
        },
        {
            title: 'Virgin Mojito Strawberry / Passion Fruit Mango / Raspberries / Kiwi',
            price: '€ 6,50',
        },
    ]
    const menuMocktails_nl = [
        {
            title: 'Ice tea Perzik, Mangue, Banaan, Ananas, Komkommer, Pompelmoes',
            price: '€ 3,75',
        },
        {
            title: 'Limonade',
            price: '€ 3,75',
        },
        {
            title: 'Aardbei / Rozen',
            price: '€ 3,95',
        },
        {
            title: 'Watermeloen',
            price: '€ 3,95',
        },
        {
            title: 'Gember',
            price: '€ 4,25',
        },
        {
            title: 'Eskimo',
            price: '€ 4,50',
        },
        {
            title: 'Apple Julep',
            price: '€ 4,60',
        },
        {
            title: 'Virgin Mojito',
            price: '€ 6,00',
        },
        {
            title: 'Virgin Mojito Strawberry / Passion Fruit Mango / Raspberries / Kiwi',
            price: '€ 6,50',
        },
    ]

    const menuCocktails = [
        {
            title: 'Kir',
            price: '€ 5,00',
        },
        {
            title: 'Kir Royal',
            price: '€ 6,50',
        },
        {
            title: 'Aperol Spritz',
            price: '€ 7,00',
        },
        {
            title: 'Special Green Wine',
            price: '€ 5,50',
        },
        {
            title: 'Piña Colada',
            price: '€ 7,50',
        },
        {
            title: 'Mojito',
            price: '€ 7,00',
        },
        {
            title: 'Mojito Strawberry / Kiwi / Raspberries / Mango / Passion Fruit',
            price: '€ 7,50',
        },
        {
            title: 'Thermae Cocktail',
            price: '€ 8,50',
        },
    ]

    const menuOtherDrinks = [
        {
            title: 'Porto rood - rouge - red',
            price: '€ 5,00',
        },
        {
            title: 'Martini rosso - bianco',
            price: '€ 5,00',
        },
        {
            title: 'Safari / Pisang Ambon / Campari / Passoa',
            price: '€ 5,00',
        },
        {
            title: 'Ricard',
            price: '€ 5,00',
        },
        {
            title: 'Cognac / Bacardi / Vodka / Amaretto',
            price: '€ 6,00',
        },
        {
            title: 'Havana Club / Jack Daniels',
            price: '€ 7,00',
        },
        {
            title: 'Johnnie Walker Red Label',
            price: '€ 6,00',
        },
        {
            title: 'Gin',
            price: '€ 6,00',
        },
        {
            title: 'Gin Bombay Sapphire',
            price: '€ 8,00',
        },
        {
            title: 'Gin Hendrick’s',
            price: '€ 9,00',
        },
    ]

    const menuWine = [
        {
            title: 'House wine - White / Red / Rosé',
            price: 'Glas € 4,25 / 25 cl € 7,50 / 50 cl € 11,90',
        },
    ]
    const menuWine_fr = [
        {
            title: 'Vin maison - Blanc / Rouge / Rosé',
            price: 'Glas € 4,25 / 25 cl € 7,50 / 50 cl € 11,90',
        },
    ]
    const menuWine_nl = [
        {
            title: 'Huiswijn - Wit / Rood / Rosé',
            price: 'Glas € 4,25 / 25 cl € 7,50 / 50 cl € 11,90',
        },
    ]


    const menuBeers = [
        {
            title: 'Maes',
            price: '25 cl € 2,50',
        },
        {
            title: 'Grimbergen Blond',
            price: '33 cl € 3,65',
        },
        {
            title: 'Grimbergen Dark',
            price: '33 cl € 3,65',
        },
        {
            title: 'Maes Alcohol-free',
            price: '25 cl € 2,50',
        },
        {
            title: 'Grimbergen Tripel 9°',
            price: '33 cl € 4,95',
        },
        {
            title: 'Grimbergen Optimo Bruno 10°',
            price: '33 cl € 4,95',
        },
        {
            title: 'Palm',
            price: '25 cl € 2,95',
        },
        {
            title: 'White beer',
            price: '25 cl € 2,95',
        },
        {
            title: 'Kriek Lindemans',
            price: '25 cl € 2,95',
        },
        {
            title: 'Geuze Lindemans',
            price: '25 cl € 2,95',
        },
        {
            title: 'Duvel',
            price: '33 cl € 4,20',
        },
    ]
    const menuBeers_fr = [
        {
            title: 'Maes',
            price: '25 cl € 2,50',
        },
        {
            title: 'Grimbergen Blond',
            price: '33 cl € 3,65',
        },
        {
            title: 'Grimbergen Brun',
            price: '33 cl € 3,65',
        },
        {
            title: 'Maes Sans alcool',
            price: '25 cl € 2,50',
        },
        {
            title: 'Grimbergen Tripel 9°',
            price: '33 cl € 4,95',
        },
        {
            title: 'Grimbergen Optimo Bruno 10°',
            price: '33 cl € 4,95',
        },
        {
            title: 'Palm',
            price: '25 cl € 2,95',
        },
        {
            title: 'Bière blanche',
            price: '25 cl € 2,95',
        },
        {
            title: 'Kriek Lindemans',
            price: '25 cl € 2,95',
        },
        {
            title: 'Geuze Lindemans',
            price: '25 cl € 2,95',
        },
        {
            title: 'Duvel',
            price: '33 cl € 4,20',
        },
    ]
    const menuBeers_nl = [
        {
            title: 'Maes',
            price: '25 cl € 2,50',
        },
        {
            title: 'Grimbergen Blond',
            price: '33 cl € 3,65',
        },
        {
            title: 'Grimbergen Bruin',
            price: '33 cl € 3,65',
        },
        {
            title: 'Maes Alcoholvrij',
            price: '25 cl € 2,50',
        },
        {
            title: 'Grimbergen Tripel 9°',
            price: '33 cl € 4,95',
        },
        {
            title: 'Grimbergen Optimo Bruno 10°',
            price: '33 cl € 4,95',
        },
        {
            title: 'Palm',
            price: '25 cl € 2,95',
        },
        {
            title: 'Steenbrugge witbier',
            price: '25 cl € 2,95',
        },
        {
            title: 'Kriek Lindemans',
            price: '25 cl € 2,95',
        },
        {
            title: 'Geuze Lindemans',
            price: '25 cl € 2,95',
        },
        {
            title: 'Duvel',
            price: '33 cl € 4,20',
        },
    ]

    const menuBubbles = [
        {
            title: 'Cava (Spain)',
            price: 'Glas - Verre € 5,00 / 75 cl € 24,90',
        },
        {
            title: 'Champagne (France)',
            price: '37,5 cl € 24,00 / 75 cl € 47,00 ',
        },
    ]

    return (
        <>
            <div className='content_menu-items' id='g1'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Snacks / Starters"}
                          {lang === 'fr' && "Snacks / Entrées"}
                          {lang === 'nl' && "Snacks / Voorgerechten"}
                      </span>
                {lang === 'en' && menuStarters.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuStarters_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuStarters_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g2'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Salades"}
                          {lang === 'fr' && "Salades"}
                          {lang === 'nl' && "Salads"}
                      </span>
                {lang === 'en' && menuSalads.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuSalads_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuSalads_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g3'>
                      <span className='content_menu-title'>
                          {lang === 'en' && "Pasta"}
                          {lang === 'fr' && "Pâtes"}
                          {lang === 'nl' && "Pâtes"}
                      </span>
                {lang === 'en' && menuPasta.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuPasta_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuPasta_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g4'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Fish"}
                          {lang === 'fr' && "Poisson"}
                          {lang === 'nl' && "Vis"}
                      </span>
                {lang === 'en' && menuFish.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuFish_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuFish_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g5'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Meat*/**"}
                          {lang === 'fr' && "Viande*/**"}
                          {lang === 'nl' && "Vlees*/**"}
                      </span>
                {lang === 'en' && menuMeat.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuMeat_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuMeat_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g6'>
                <span className='content_menu-title'>Desserts</span>
                {lang === 'en' && menuDesserts.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuDesserts_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuDesserts_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g7'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Pancakes"}
                          {lang === 'fr' && "Crêpes"}
                          {lang === 'nl' && "Pannenkoeken"}
                      </span>
                {lang === 'en' && menuPancakes.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuPancakes_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuPancakes_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g8'>
                <span className='content_menu-title'>Milkshakes</span>
                {lang === 'en' && menuMilkshakes.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuMilkshakes_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuMilkshakes_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g9'>
                <span className='content_menu-title'>Water - Eau</span>
                {lang === 'en' && menuWater.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuWater_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuWater_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g10'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Soft drinks"}
                            {lang === 'fr' && "Boissons froides"}
                            {lang === 'nl' && "Frisdranken"}
                        </span>
                {menuSoftDrinks.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g11'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Fruit juices"}
                            {lang === 'fr' && "Jus de fruits"}
                            {lang === 'nl' && "Fruitsappen"}
                        </span>
                {lang === 'en' && menuFruitJuices.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuFruitJuices_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuFruitJuices_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g12'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Fresh"}
                            {lang === 'fr' && "Frais"}
                            {lang === 'nl' && "Vers"}
                        </span>
                {lang === 'en' && menuFresh.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuFresh_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuFresh_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g13'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Hot Drinks"}
                            {lang === 'fr' && "Boissons chaudes"}
                            {lang === 'nl' && "Warme dranken"}
                        </span>
                {lang === 'en' && menuHotDrinks.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuHotDrinks_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuHotDrinks_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g14'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Special tea"}
                            {lang === 'fr' && "Thé spécial"}
                            {lang === 'nl' && "Speciale thee"}
                        </span>
                {lang === 'en' && menuSpecialTea.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuSpecialTea_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuSpecialTea_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g15'>
                <span className='content_menu-title'>Mocktails</span>
                {lang === 'en' && menuMocktails.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuMocktails_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuMocktails_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g16'>
                <span className='content_menu-title'>Cocktails</span>
                {menuCocktails.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g17'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Other drinks"}
                            {lang === 'fr' && "Autres boissons"}
                            {lang === 'nl' && "Andere dranken"}
                        </span>
                {menuOtherDrinks.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g18'>
                <span className='content_menu-title'>
                    {lang === 'en' && "Wine"}
                    {lang === 'fr' && "Vin"}
                    {lang === 'nl' && "Wijn"}
                </span>
                {lang === 'en' && menuWine.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuWine_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuWine_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g19'>
                      <span className='content_menu-title'>
                          {lang === 'en' && "Bubbles"}
                          {lang === 'fr' && "Des bulles"}
                          {lang === 'nl' && "Bubbels"}
                      </span>
                {menuBubbles.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='g20'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Beers"}
                          {lang === 'fr' && "Bières"}
                          {lang === 'nl' && "Bieren"}
                        </span>
                {lang === 'en' && menuBeers.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuBeers_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuBeers_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default MenuContentGrill;