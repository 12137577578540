import React, { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Logic from '../../Logic'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logo from '../../images/logo/logo.png'
import { FiArrowUpCircle } from 'react-icons/fi'

const Layout = (props) => {
  const { t, i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  const [sideBar, setSideBar] = useState(true)

  const { toggle, toggleDarkTheme } = Logic()

  const toggleSidebar = () => {
    setSideBar(!sideBar)
  }

  // close sidebar when scrolling
  const [offset, setOffset] = useState(0)
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
  }, [])
  //console.log(offset)
  if (window.onscroll && offset > 1 && sideBar === false) {
    toggleSidebar()
  }

  const [dropdownMenu, setDropdownMenu] = useState(true)
  const toggleDropdownMenu = () => {
    setDropdownMenu(!dropdownMenu)
  }

  // color svg theme
  // let bgLogo = '#1d2839'
  // let contourLogo = '#ffffff'
  let contourTheme = '#ffffff'
  let strokeTheme = '#ffffff'

  // darth and light svg theme
  if (toggle) {
    // contourLogo = '#ffffff'
    strokeTheme = '#ffffff'
    contourTheme = '#1d2839'
  } else {
    // bgLogo = '#fbfcfd'
    // contourLogo = '#000000'
    contourTheme = '#fbfcfd'
    strokeTheme = '#000000'
  }

  // for sidebar path and active padding left |
  let location = useLocation()

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  window.addEventListener('scroll', checkScrollTop)

  // keydown
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        console.log('esc pressed')
        setSideBar(!sideBar)
      }
    },
    [sideBar],
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction)

    return () => {
      document.removeEventListener('keydown', escFunction)
    }
  }, [escFunction])
  return (
    <>
      <header className='header'>
        <div className='container'>
          <FiArrowUpCircle
            className='scroll-top'
            onClick={scrollTop}
            style={{ height: 40, display: showScroll ? 'flex' : 'none' }}
          />

          <div className='header__items'>
            <section className='logo'>
              <NavLink to='/'>
                <img style={{ width: 170, marginTop: 30 }} src={logo} alt='logo' />
              </NavLink>
            </section>

            <section className='navbar'>
              <div className='path-restaurant'>
                <div className='path-restaurant__item'>
                  {location.pathname !== '/' && (
                    <NavLink to='/'>
                      <span>{t('sidebar.home')}</span>
                    </NavLink>
                  )}
                </div>
              </div>

              <div onClick={toggleDropdownMenu} className='internalization-toggle'>
                <div className='container-language'>
                  {i18n.language === 'en' && <span>EN</span>}
                  {i18n.language === 'fr' && <span>FR</span>}
                  {i18n.language === 'nl' && <span>NL</span>}
                </div>
                <div className={!dropdownMenu ? 'dropdown-menu' : 'dropdown-menu display-none'}>
                  <div className='dropdown-menu_check'></div>
                  <button
                    className={
                      i18n.language === 'en'
                        ? 'dropdown-menu__button_top active_button'
                        : 'dropdown-menu__button_top'
                    }
                    onClick={() => changeLanguage('en')}>
                    English
                  </button>
                  <button
                    className={
                      i18n.language === 'nl'
                        ? 'dropdown-menu__button_middle active_button'
                        : 'dropdown-menu__button_middle'
                    }
                    onClick={() => changeLanguage('nl')}>
                    Netherlandish
                  </button>
                  <button
                    className={
                      i18n.language === 'fr'
                        ? 'dropdown-menu__button_bottom active_button'
                        : 'dropdown-menu__button_bottom'
                    }
                    onClick={() => changeLanguage('fr')}>
                    French
                  </button>
                </div>
              </div>

              <div onClick={toggleSidebar} className='sidebar-toggle'>
                <div onClick={toggleSidebar}>
                  <div className={!sideBar ? 'open' : ''} id='sidebar-toggle'>
                    <span className={!sideBar ? 'open' : ''}></span>
                    <span className={!sideBar ? 'open' : ''}></span>
                    <span className={!sideBar ? 'open' : ''}></span>
                    <span className={!sideBar ? 'open' : ''}></span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </header>

      {/*overlay*/}
      <div onClick={toggleSidebar} className={sideBar ? 'overlay overlay-none' : 'overlay'}></div>

      {/*sidebar*/}
      <div
        id='sidebar'
        // className={!sideBar ? "sidebar translate-sidebar" : "sidebar translate-sidebar-none "}>
        className={sideBar ? 'sidebar' : 'sidebar active '}>
        {/*display-none*/}
        <div className='sidebar__wrap'>
          <NavLink onClick={toggleSidebar} to='/'>
            <h3 className={location.pathname === '/' ? 'active-link' : ''}>
              {i18n.language === 'nl' && 'huis'}
              {i18n.language === 'fr' && 'domicile'}
              {i18n.language === 'en' && 'home'}
            </h3>
          </NavLink>
          <NavLink onClick={toggleSidebar} to='/boetfort-menu'>
            <h3 className={location.pathname === '/boetfort-menu' ? 'active-link' : ''}>
              {i18n.language === 'nl' && 'boetfortmenu'}
              {i18n.language === 'fr' && 'menu boetfort'}
              {i18n.language === 'en' && 'boetfort menu'}
            </h3>
          </NavLink>
          <NavLink onClick={toggleSidebar} to='/grimbergen-menu'>
            <h3 className={location.pathname === '/grimbergen-menu' ? 'active-link' : ''}>
              {i18n.language === 'nl' && 'grimbergenmenu'}
              {i18n.language === 'fr' && 'menu grimbergen'}
              {i18n.language === 'en' && 'grimbergen menu'}
            </h3>
          </NavLink>
          {/*<NavLink onClick={toggleSidebar} to='/contact'>*/}
          {/*  <h3 className={location.pathname === '/contact' ? 'active-link' : ''}>*/}
          {/*    {t('sidebar.contact')}*/}
          {/*  </h3>*/}
          {/*</NavLink>*/}
          <div className='sidebar__internalization-toggle'>
            <button
              className={i18n.language === 'en' ? 'active' : 'black'}
              onClick={() => changeLanguage('en')}>
              English
            </button>
            <button
              className={i18n.language === 'fr' ? 'active' : 'black'}
              onClick={() => changeLanguage('fr')}>
              French
            </button>
            <button
              className={i18n.language === 'nl' ? 'active' : 'black'}
              onClick={() => changeLanguage('nl')}>
              Netherlandish
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
