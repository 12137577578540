import React, {Suspense} from "react";
import ReactDOM from 'react-dom';
import './style/index.scss';
import App from './App';
import {Provider} from "react-redux";
import store from "./store/store";
// @ts-ignore
import {BrowserRouter} from "react-router-dom";
import './i18n';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>

            <Suspense fallback={<div>Loading...</div>}>
                <App/>
            </Suspense>

        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

