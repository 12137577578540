import React from 'react';
import { useTranslation } from 'react-i18next'

const MenuContent = () => {
    const {i18n } = useTranslation()
    let lang = i18n.language

    const menuStarters = [
        {

            title: 'Portion of cheese or salami',
            price: '€ 6,00',
        },
        {

            title:
                'Mixed portion of salami and cheese',
            price: '€ 7,00',
        },
        {

            title:
                'Mixed portion salami, cheese and olives',
            price: '€ 8,00',
        },
        {

            title: 'Soup of the day',
            price: '€ 6,00',
        },
        {

            title: 'Cheese croquettes',
            price: '€ 14,50',
        },
        {

            title:
                'Grey shrimp croquettes',
            price: '€ 16,50',
        },
        {

            title: 'Duo of shrimp and cheese croquettes',
            price: '€ 15,50',
        },
        {

            title:
                'Salmon, rice and ginger croquettes, béarnaise sauce (6 pieces)',
            price: '€ 8,50',
        },
        {

            title:
                'Scampi with garlic (8 pieces)',
            price: '€ 18,25',
        },
        {

            title:
                'Chicken wings with cornflakes',
            price: '€ 9,00',
        },
        {

            title: 'Calamares à la romana (tartaarsaus -sauce tartare)',
            price: '€ 10,50',
        },
        {

            title:
                'Boetfort Degustation Pallet. Radishes, salmon and cheese croquettes, chicken wings,\n' +
                'calamares, tartare and béarnaise sauce',
            price: '€ 16,25',
        },
        {

            title: 'Croque Monsieur (1 piece)',
            price: ' € 7,00',
        },
        {

            title: 'Croque Madame (1 piece)',
            price: ' € 7,00',
        },
    ]
    const menuStarters_fr = [
        {

            title: 'Portion fromage ou salami',
            price: '€ 6,00',
        },
        {

            title:
                'Portion mixte salami et fromage',
            price: '€ 7,00',
        },
        {

            title:
                'Portion mixte salami, fromage et olives',
            price: '€ 8,00',
        },
        {

            title: 'Potage du jour',
            price: '€ 6,00',
        },
        {

            title: 'Croquettes de fromage',
            price: '€ 14,50',
        },
        {

            title:
                'Croquettes de crevettes',
            price: '€ 16,50',
        },
        {

            title: 'Duo de croquettes fromage et crevettes',
            price: '€ 15,50',
        },
        {

            title:
                'Croquettes de saumon, riz et gingembre, sauce béarnaise (6 pièces)',
            price: '€ 8,50',
        },
        {

            title:
                'Scampis à l‘ail (8 pièces)',
            price: '€ 18,25',
        },
        {

            title:
                'Ailles de poulet aux pétales de maïs',
            price: '€ 9,00',
        },
        {

            title: 'Calamares à la romana (tartaarsaus -sauce tartare)',
            price: '€ 10,50',
        },
        {

            title:
                'Boetfort Degustation Pallet. Radis, croquettes de saumon et fromage, ailles de poulet,\n' +
                'calamares, sauce tartare et béarnaise',
            price: '€ 16,25',
        },
        {

            title: 'Croque Monsieur (1 pièce)',
            price: ' € 7,00',
        },
        {

            title: 'Croque Madame (1 pièce)',
            price: ' € 7,00',
        },
    ]
    const menuStarters_nl = [
        {

            title: 'Portie kaas of salami',
            price: '€ 6,00',
        },
        {

            title:
                'Portie gemengd salami en kaas',
            price: '€ 7,00',
        },
        {

            title:
                'Portie gemengd salami, kaas en olijven',
            price: '€ 8,00',
        },
        {

            title: 'Soep van de dag',
            price: '€ 6,00',
        },
        {

            title: 'Huisgemaakte kaaskroketten',
            price: '€ 14,50',
        },
        {

            title:
                'Kroketten met Zeebrugse grijze garnalen',
            price: '€ 16,50',
        },
        {

            title: 'Duo van kaas- en garnaalkroket',
            price: '€ 15,50',
        },
        {

            title:
                'Kroketten van zalm, rijst en gember, bearnaisesaus (6 stuks',
            price: '€ 8,50',
        },
        {

            title:
                'Scampi‘s met look (8 stuks)',
            price: '€ 18,25',
        },
        {

            title:
                'Kippenvleugels met cornflakes',
            price: '€ 9,00',
        },
        {

            title: 'Calamares à la romana (tartaarsaus -sauce tartare)',
            price: '€ 10,50',
        },
        {

            title:
                'Boetfort Degustation Pallet. Radijzen, zalmkroketten, kippenvleugels, calamares, kaaskroketten,\n' +
                'tartaar- en bearnaisesaus',
            price: '€ 16,25',
        },
        {

            title: 'Croque Monsieur (1 stuk)',
            price: ' € 7,00',
        },
        {

            title: 'Croque Madame (1 stuk)',
            price: ' € 7,00',
        },
    ]

    const menuSalads = [
        {
            title:
                'Royal salad. \n' +
                ' - Scampi, scallops, calamari, shrimps, orange, feta, carrots,\n' +
                'cherry tomatoes and pineapple.',
            price: '€ 20,75',
        },
        {
            title:
                'Shepherd‘s salad. \n' +
                ' - Goat cheese with honey and thyme, cherry tomatoes, carrots, apple,\n' +
                'green beans, dried fruit.',
            price: '€ 17,75',
        },
        {
            title:
                'With smoked salmon. \n' +
                ' - Salad mix, dried tomatoes, grapefruit, lemon, smoked salmon and feta.',
            price: '€ 19,75',
        },
        {
            title:
                'Chicken salad. \n' +
                ' - Chicken, eggs, tomato, croutons.',
            price: '€ 17,75',
        },
    ]
    const menuSalads_fr = [
        {
            title:
                'Salade Royale \n' +
                'Scampis, noix st-jacques, calamares, crevettes, orange, feta, carottes,\n' +
                'tomates cerises et ananas.'
            ,
            price: '€ 20,75',
        },
        {
            title:
                'Salade du berger \n' +
                'Fromage de chèvre au miel et thym, tomates cerises, carottes, \n' +
                'pommes, haricots verts, fruit secs.',
            price: '€ 17,75',
        },
        {
            title:
                'Au saumon fumé \n' +
                'Mélange de salades, tomates sechées, pamplemousse, citron,\n' +
                'saumon fumé et feta.',
            price: '€ 19,75',
        },
        {
            title:
                'Salade poulet \n' +
                ' - Poulet, oeufs, tomate, croûtons.',
            price: '€ 17,75',
        },
    ]
    const menuSalads_nl = [
        {
            title:
                'Salade Royale \n' +
                'Scampi‘s, sint-jakobsvruchten, inktvisringen, garnalen, sinaas,\n' +
                'feta, wortelen, kerstomaten en ananas.',
            price: '€ 20,75',
        },
        {
            title:
                'Herderssalade \n' +
                'Geitenkaas met honing en tijm, kerstomaat, wortelen, appel, \n' +
                'groene bonen, gedroogd fruit.',
            price: '€ 17,75',
        },
        {
            title:
                'Salade met gerookte zalm \n' +
                'Salade mix, zongedroogde tomaten, pompelmoes, citroen,\n' +
                'gerookte zalm en feta.',
            price: '€ 19,75',
        },
        {
            title:
                'Salade kip \n' +
                'Kip, eieren, tomaat, croutons.\n',
            price: '€ 17,75',
        },
    ]

    const menuPasta = [
        {
            title:
                'Courgette, garlic, basil oil, parmesan cheese, tomato sauce',
            price: '€ 19,50',
        },
        {
            title: 'Spaghetti bolognaise',
            price: '€ 11,50',
        },
        {
            title:
                'Bolognaise, mushrooms, ham, bell pepper, cream',
            price: '€ 13,75',
        },
        {
            title: 'Lasagne maison',
            price: '€ 14,50',
        },
        {
            title:
                'Tagliatelli with two types of salmon and cream',
            price: '€ 19,50',
        },
    ]
    const menuPasta_fr = [
        {
            title:
                'Courgette, ail, huile de basilic, parmesan, sauce tomate',
            price: '€ 19,50',
        },
        {
            title: 'Spaghetti bolognaise',
            price: '€ 11,50',
        },
        {
            title:
                'Bolognaise, champignons, jambon, paprika, crème',
            price: '€ 13,75',
        },
        {
            title: 'Lasagne maison',
            price: '€ 14,50',
        },
        {
            title:
                'Tagliatelli aux deux saumons et crème\n',
            price: '€ 19,50',
        },
    ]
    const menuPasta_nl = [
        {
            title:
                'Tagliatelli met scampi‘s. \n' +
                'Courgette, knoflook, basilicumolie, parmezaanse kaas, tomatensaus',
            price: '€ 19,50',
        },
        {
            title: 'Spaghetti bolognaise',
            price: '€ 11,50',
        },
        {
            title:
                'Spaghetti maison. \n' +
                'Bolognaise, champignons, hesp, paprika, room',
            price: '€ 13,75',
        },
        {
            title: 'Lasagne maison',
            price: '€ 14,50',
        },
        {
            title:
                'Tagliatelli met twee soorten zalm en room. ',
            price: '€ 19,50',
        },
    ]

    const menuFish = [
        {
            title:
                'Grilled salmon / “à la plancha“. \n' +
                'Salad, béarnaise sauce, side dish',
            price: '€ 23,50',
        },
    ]
    const menuFish_fr = [
        {
            title:
                'Saumon grillé \n' +
                ' - Salade, sauce béarnaise, plat d‘accompagnement\n',
            price: '€ 23,50',
        },
    ]
    const menuFish_nl = [
        {
            title:
                'Gegrilde zalm - Saumon grillé - Grilled salmon / “à la plancha“. \n' +
                'Salade, bearnaisesaus, bijgerecht',
            price: '€ 23,50',
        },
    ]

    const menuMeat = [
        {
            title:
                'Beef steak “à la plancha“, salad and side dish (250 gr.)',
            price: '€ 19,00',
        },
        {
            title:
                'Steak tartare (self-preparation), salad and side dish',
            price: '€ 17,50',
        },
        {
            title:
                'Flemish beef and beer stew',
            price: '€ 18,00',
        },
        {
            title:
                'Rundsburger. \n' +
                'Beef, sun dried tomatoes',
            price: '€ 18,50',
        },
    ]
    const menuMeat_fr = [
        {
            title:
                'Steak de boeuf ‘à la plancha‘, salade, plat d‘accompagnement (250 gr.)',
            price: '€ 19,00',
        },
        {
            title:
                'Américain (non préparé), salade et plat d‘accompagnement\n',
            price: '€ 17,50',
        },
        {
            title:
                'Carbonade Flamande',
            price: '€ 18,00',
        },
        {
            title:
                'Viande de boeuf, tomates sechées',
            price: '€ 18,50',
        },
    ]
    const menuMeat_nl = [
        {
            title:
                'Rundssteak “à la plancha“ met salade en bijgerecht (250 gr.). \n',
            price: '€ 19,00',
        },
        {
            title:
                'Américain (zelf te bereiden), salade en bijgerecht. \n',
            price: '€ 17,50',
        },
        {
            title:
                'Stoofvlees op Vlaamse wijze. \n',
            price: '€ 18,00',
        },
        {
            title:
                'Rundsburger. \n' +
                'Rundsvlees, zongedroogde tomaten\n',
            price: '€ 18,50',
        },
    ]

    const menuDesserts = [
        {
            title:
                'Apple strudel with vanilla icecream',
            price: '€ 8,50',
        },
        {
            title: 'Tiramisu with speculoos',
            price: '€ 8,00',
        },
        {
            title:
                'Moelleux au chocolat with raspberry coulis and vanilla ice cream',
            price: '€ 9,00',
        },
        {
            title:
                'Vanilla, Mocha, Chocolate, Strawberry or Banana',
            price: '€ 6,00',
        },
        {
            title:
                'Glace au moka, caramel, noisettes - Mocha ice cream, caramel, nuts',
            price: '€ 8,00',
        },
    ]
    const menuDesserts_fr = [
        {
            title:
                'Strudel aux pommes et sa glace vanille',
            price: '€ 8,50',
        },
        {
            title: 'Tiramisu au spéculoos',
            price: '€ 8,00',
        },
        {
            title:
                'Moeulleux au chocolat au coulis de framboises et sa glace vanille',
            price: '€ 9,00',
        },
        {
            title:
                'Vanille, Moka, Chocolat, Fraise ou Banane\n',
            price: '€ 6,00',
        },
        {
            title:
                'Mokka-ijs, karamel, nootjes\n',
            price: '€ 8,00',
        },
    ]
    const menuDesserts_nl = [
        {
            title:
                'Apfelstrudel met vanille-ijs.',
            price: '€ 8,50',
        },
        {
            title: 'Tiramisu van speculaas.',
            price: '€ 8,00',
        },
        {
            title:
                'Moelleux au chocolat met frambozencoulis en vanille-ijs.',
            price: '€ 9,00',
        },
        {
            title:
                'Vanille, Moka, Chocolat, Fraise ou Banane\n',
            price: '€ 6,00',
        },
        {
            title:
                'Mokka-ijs, karamel, nootjes\n',
            price: '€ 8,00',
        },
    ]

    const menuPancakes = [
        {
            title:
                'Pancakes (2 pieces) with sugar',
            price: '€ 6,00',
        },
        {
            title:
                'Pancakes (2 pieces) with jam',
            price: '€ 6,00',
        },
        {
            title:
                'Mikado. \n' +
                '2 pieces with ice cream and chocolate sauce',
            price: '€ 7,50',
        },
    ]
    const menuPancakes_fr = [
        {
            title:
                'Crêpes (2 pièces) au sucre',
            price: '€ 6,00',
        },
        {
            title:
                'Crêpes (2 pièces) à la confiture',
            price: '€ 6,00',
        },
        {
            title:
                'Mikado. \n' +
                '2 pièces avec de la glace et sauce au chocolat\n',
            price: '€ 7,50',
        },
    ]
    const menuPancakes_nl = [
        {
            title:
                'Pannenkoeken (2 stuks) met suiker. \n',
            price: '€ 6,00',
        },
        {
            title:
                'Pannenkoeken (2 stuks) met confituur. \n',
            price: '€ 6,00',
        },
        {
            title:
                'Mikado. \n' +
                ' - 2 stuks met ijs en chocoladesaus\n',
            price: '€ 7,50',
        },
    ]

    const menuWater = [
        {
            title: 'Still water Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'Still water Aqua Panna',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
        {
            title: 'Sparkling water Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'Sparkling water San Pellegrino',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
    ]
    const menuWater_fr = [
        {
            title: 'Eau plate Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'Aqua Panna',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
        {
            title: ' Eau pétillante Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'San Pellegrino',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
    ]
    const menuWater_nl = [
        {
            title: 'Plat water Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'Aqua Panna',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
        {
            title: 'Spuitwater Thermae',
            price: '20 cl € 0,95 / 50 cl € 1,95 / 1 l € 3,75',
        },
        {
            title: 'San Pellegrino',
            price: '50 cl € 3,50 / 1 l € 6,50',
        },
    ]

    const menuSoftDrinks = [
        {
            title: 'Coca Cola / Zero / Fanta / Sprite',
            price: '20 cl € 2,50',
        },
        {
            title: 'Schweppes Tonic / Bitter',
            price: '25 cl € 2,80',
        },
        {
            title: 'Tonic Fever Tree Indian / Elderflower / Mediterranean',
            price: '20 cl € 3,60',
        },
        {
            title: 'Tönissteiner Lemon / Orange',
            price: '20 cl € 2,90',
        },
        {
            title: 'Canada Dry',
            price: '20 cl € 2,90',
        },
        {
            title: 'Cécémel',
            price: '20 cl € 2,60',
        },
    ]

    const menuFruitJuices = [
        {
            title: 'Orange juice',
            price: '20 cl € 2,50',
        },
        {
            title: 'Pineapple juice',
            price: '20 cl € 2,50',
        },
        {
            title: ' Apple juice',
            price: '20 cl € 2,50',
        },
        {
            title: 'Grapefruit juice',
            price: '20 cl € 2,50',
        },
        {
            title: 'Tomato juice',
            price: '20 cl € 2,50',
        },
        {
            title: 'Orange juice',
            price: '20 cl € 4,25',
        },
    ]
    const menuFruitJuices_fr = [
        {
            title: 'Jus d‘orange',
            price: '20 cl € 2,50',
        },
        {
            title: 'Jus d‘ananas ',
            price: '20 cl € 2,50',
        },
        {
            title: 'Jus de pomme',
            price: '20 cl € 2,50',
        },
        {
            title: 'Jus de pamplemousse',
            price: '20 cl € 2,50',
        },
        {
            title: 'Jus de tomates',
            price: '20 cl € 2,50',
        },
        {
            title: 'Jus d’orange',
            price: '20 cl € 4,25',
        },
    ]
    const menuFruitJuices_nl = [
        {
            title: 'Sinaasappelsap',
            price: '20 cl € 2,50',
        },
        {
            title: 'Ananassap',
            price: '20 cl € 2,50',
        },
        {
            title: 'Appelsap',
            price: '20 cl € 2,50',
        },
        {
            title: 'Pompelmoessap',
            price: '20 cl € 2,50',
        },
        {
            title: 'Tomatensap',
            price: '20 cl € 2,50',
        },
        {
            title: 'Sinaasappelsap',
            price: '20 cl € 4,25',
        },
    ]

    const menuHotDrinks = [
        {
            title: 'Coffee / Espresso / Décaféiné',
            price: '€ 2,50',
        },
        {
            title: 'Tea',
            price: '€ 2,50',
        },
        {
            title: 'Coffee with hot milk',
            price: '€ 2,90',
        },
        {
            title: 'With whipped cream',
            price: '€ 2,90',
        },
        {
            title: 'With foamed milk',
            price: '€ 2,90',
        },
        {
            title: 'Hote chocolate milk',
            price: '€ 3,00',
        },
        {
            title: 'Vanilla latte',
            price: '€ 4,60',
        },
        {
            title: 'Irish / Italian / French Coffee',
            price: '€ 6,90',
        },
    ]
    const menuHotDrinks_fr = [
        {
            title: 'Café',
            price: '€ 2,50',
        },
        {
            title: 'Thé',
            price: '€ 2,50',
        },
        {
            title: 'Lait russe',
            price: '€ 2,90',
        },
        {
            title: 'Avec crème fraîche',
            price: '€ 2,90',
        },
        {
            title: 'Avec mousse au lait',
            price: '€ 2,90',
        },
        {
            title: 'Chocolat chaud',
            price: '€ 3,00',
        },
        {
            title: 'Vanilla latte',
            price: '€ 4,60',
        },
        {
            title: 'Irish / Italian / French Coffee',
            price: '€ 6,90',
        },
    ]
    const menuHotDrinks_nl = [
        {
            title: 'Koffie',
            price: '€ 2,50',
        },
        {
            title: 'Thee',
            price: '€ 2,50',
        },
        {
            title: 'Koffie verkeerd',
            price: '€ 2,90',
        },
        {
            title: 'Cappuccino met slagroom',
            price: '€ 2,90',
        },
        {
            title: 'Cappuccino met melkschuim',
            price: '€ 2,90',
        },
        {
            title: 'Warme chocomelk',
            price: '€ 3,00',
        },
        {
            title: 'Vanilla latte',
            price: '€ 4,60',
        },
        {
            title: 'Irish / Italian / French Coffee',
            price: '€ 6,90',
        },
    ]

    const menuSpecialTea = [
        {
            title: 'Thym and honey tea',
            price: '€ 4,50',
        },
        {
            title: 'Ginger tea',
            price: '€ 4,50',
        },
        {
            title: 'Tisane d’assouan / Hermès / Kyoto / Aan de oever van de Nijl',
            price: '€ 3,95',
        },
        {
            title: 'Nilgiri thiashola bio (black tea) ',
            price: '€ 3,95',
        },
        {
            title:
                'Fresh mint tea with honey',
            price: '€ 3,50',
        },
        {
            title: 'Pure green tea',
            price: '€ 3,95',
        },
    ]
    const menuSpecialTea_fr = [
        {
            title: 'Thé thym et miel',
            price: '€ 4,50',
        },
        {
            title: ' Thé gingembre',
            price: '€ 4,50',
        },
        {
            title: 'Tisane d’assouan / Hermès / Kyoto / Aan de oever van de Nijl',
            price: '€ 3,95',
        },
        {
            title: 'Nilgiri thiashola bio (thé noir) ',
            price: '€ 3,95',
        },
        {
            title:
                'Thé à la menthe fraîche au miel',
            price: '€ 3,50',
        },
        {
            title: ' Thé vert pur',
            price: '€ 3,95',
        },
    ]
    const menuSpecialTea_nl = [
        {
            title: 'Tijm thee met honing',
            price: '€ 4,50',
        },
        {
            title: 'Gember thee',
            price: '€ 4,50',
        },
        {
            title: 'Tisane d’assouan / Hermès / Kyoto / Aan de oever van de Nijl',
            price: '€ 3,95',
        },
        {
            title: 'Nilgiri thiashola bio (zwarte thee) ',
            price: '€ 3,95',
        },
        {
            title:
                'Verse muntthee met honing.',
            price: '€ 3,50',
        },
        {
            title: 'Pure groene thee',
            price: '€ 3,95',
        },
    ]

    const menuCocktails = [
        {
            title: 'Cocktail Boetfort',
            price: '€ 8,00',
        },
        {
            title: 'Kir',
            price: '€ 5,50',
        },
        {
            title: 'Kir Royal',
            price: '€ 7,00',
        },
        {
            title: 'Special Green Wine',
            price: '€ 5,50',
        },
        {
            title: 'Piña Colada / Bitter Exotic / Orange Spice Martini / Cuba Libre',
            price: '€ 7,50',
        },
        {
            title: 'Mojito',
            price: '€ 7,50',
        },
        {
            title: 'Mojito Strawberry / Kiwi / Raspberries / Mango / Passion Fruit',
            price: '€ 8,00',
        },
        {
            title: 'Campari Spritz / Amaretto Spritz / Aperol Spritz',
            price: '€ 7,50',
        },
    ]

    const menuAperitif = [
        {
            title: 'Porto rood - rouge - red',
            price: '€ 5,00',
        },
        {
            title: 'Martini rosso - bianco',
            price: '€ 5,00',
        },
        {
            title: 'Martini rosso - bianco & cava',
            price: '€ 5,00',
        },
        {
            title: 'Picon vin blanc',
            price: '€ 5,00',
        },
        {
            title: 'Cognac / Wodka / Whisky / Ricard',
            price: '€ 7,00',
        },
        {
            title: 'Limoncello',
            price: '€ 5,50',
        },
        {
            title: 'Campari / Passoa / Safari / Pisang Ambon',
            price: '€ 5,00',
        },
        {
            title: 'Bacardi / Sherry / Amaretto',
            price: '€ 6,00',
        },
    ]

    const menuGin = [
        {
            title: 'Gin',
            price: '€ 7,00',
        },
        {
            title: 'Gin Bombay Sapphire',
            price: '€ 8,50',
        },
        {
            title: 'Gin Hendrick’s',
            price: '€ 9,50',
        },
        {
            title: 'Gin Henry Morgan‘s',
            price: '€ 8,00',
        },
    ]

    const menuIceTea = [
        {
            title: 'Peach',
            price: '€ 3,95',
        },
        {
            title: 'Mango',
            price: '€ 3,95',
        },
        {
            title: 'Strawberry',
            price: '€ 3,95',
        },
        {
            title: 'Melon',
            price: '€ 3,95',
        },
        {
            title: 'Pineapple',
            price: '€ 3,95',
        },
        {
            title: 'Banana',
            price: '€ 3,95',
        },
        {
            title: 'Cucumber',
            price: '€ 3,95',
        },
        {
            title: 'Grapefruit',
            price: '€ 3,95',
        },
        {
            title: 'Cherry',
            price: '€ 3,95',
        },
    ]
    const menuIceTea_fr = [
        {
            title: 'Pêche',
            price: '€ 3,95',
        },
        {
            title: 'Mangue',
            price: '€ 3,95',
        },
        {
            title: 'Aardbei',
            price: '€ 3,95',
        },
        {
            title: 'Meloen',
            price: '€ 3,95',
        },
        {
            title: 'Ananas',
            price: '€ 3,95',
        },
        {
            title: 'Banane',
            price: '€ 3,95',
        },
        {
            title: 'Concombre',
            price: '€ 3,95',
        },
        {
            title: 'Pamplemousse',
            price: '€ 3,95',
        },
        {
            title: 'Cerise',
            price: '€ 3,95',
        },
    ]
    const menuIceTea_nl = [
        {
            title: 'Perzik',
            price: '€ 3,95',
        },
        {
            title: 'Mangue',
            price: '€ 3,95',
        },
        {
            title: 'Aardbei',
            price: '€ 3,95',
        },
        {
            title: 'Meloen',
            price: '€ 3,95',
        },
        {
            title: 'Ananas',
            price: '€ 3,95',
        },
        {
            title: 'Banaan',
            price: '€ 3,95',
        },
        {
            title: 'Komkommer',
            price: '€ 3,95',
        },
        {
            title: 'Pompelmoes',
            price: '€ 3,95',
        },
        {
            title: 'Kersen',
            price: '€ 3,95',
        },
    ]

    const menuLimonade = [
        {
            title: 'Lemonade',
            price: '€ 3,85',
        },
        {
            title: 'Strawberry',
            price: '€ 4,35',
        },
        {
            title: 'Kiwi',
            price: '€ 4,35',
        },
        {
            title: 'Melon',
            price: '€ 4,35',
        },
        {
            title: 'Ginger',
            price: '€ 4,50',
        },
        {
            title: 'Peach',
            price: '€ 4,50',
        },
    ]
    const menuLimonade_fr = [
        {
            title: 'Limonade',
            price: '€ 3,85',
        },
        {
            title: 'Fraise',
            price: '€ 4,35',
        },
        {
            title: 'Kiwi',
            price: '€ 4,35',
        },
        {
            title: 'Meloen',
            price: '€ 4,35',
        },
        {
            title: 'Gingembre',
            price: '€ 4,50',
        },
        {
            title: 'Pêche',
            price: '€ 4,50',
        },
    ]
    const menuLimonade_nl = [
        {
            title: 'Limonade',
            price: '€ 3,85',
        },
        {
            title: 'Aardbei',
            price: '€ 4,35',
        },
        {
            title: 'Kiwi',
            price: '€ 4,35',
        },
        {
            title: 'Meloen',
            price: '€ 4,35',
        },
        {
            title: 'Gember',
            price: '€ 4,50',
        },
        {
            title: 'Perzik',
            price: '€ 4,50',
        },
    ]

    const menuMocktails = [
        {
            title: 'Eskimo',
            price: '€ 4,70',
        },
        {
            title: 'Chinees',
            price: '€ 4,70',
        },
        {
            title: 'Apple Julep',
            price: '€ 4,90',
        },
        {
            title: 'Applemint Smash ',
            price: '€ 4,90',
        },
        {
            title: 'Acapulco',
            price: '€ 4,90',
        },
        {
            title: 'Orange Squash',
            price: '€ 4,90',
        },
        {
            title: 'Virgin Mojito',
            price: '€ 6,20',
        },
        {
            title: 'Virgin Mojito Strawberry / Passion Fruit / Mango / Raspberries / Kiwi',
            price: '€ 6,70',
        },
    ]

    const menuSmoothies = [
        {
            title: 'Banana',
            price: '€ 6,00',
        },
        {
            title: 'Coconut',
            price: '€ 6,00',
        },
        {
            title: 'Passion Frui',
            price: '€ 6,00',
        },
        {
            title: 'Mango',
            price: '€ 6,00',
        },
        {
            title: 'Strawberry',
            price: '€ 6,00',
        },
        {
            title: 'Peach',
            price: '€ 6,00',
        },
    ]
    const menuSmoothies_fr = [
        {
            title: 'Banane',
            price: '€ 6,00',
        },
        {
            title: 'Noix de coco',
            price: '€ 6,00',
        },
        {
            title: 'Fruits de la passion',
            price: '€ 6,00',
        },
        {
            title: 'Mangue',
            price: '€ 6,00',
        },
        {
            title: 'Fraise',
            price: '€ 6,00',
        },
        {
            title: 'Pêche',
            price: '€ 6,00',
        },
    ]
    const menuSmoothies_nl = [
        {
            title: 'Banaan',
            price: '€ 6,00',
        },
        {
            title: 'Kokos',
            price: '€ 6,00',
        },
        {
            title: 'Passievrucht',
            price: '€ 6,00',
        },
        {
            title: 'Mangue',
            price: '€ 6,00',
        },
        {
            title: 'Aardbei',
            price: '€ 6,00',
        },
        {
            title: 'Perzik',
            price: '€ 6,00',
        },
    ]


    const menuBeers = [
        {
            title: 'Boetfort pils',
            price: '25 cl € 2,50',
        },
        {
            title: 'Boetfort blond(e) / donker - brune - dark',
            price: '33 cl € 3,90',
        },
        {
            title: 'Boetfort tripel - triple',
            price: '33 cl € 4,30',
        },
        {
            title: 'Steenbrugge witbier Bière blanche - White beer',
            price: '25 cl € 2,95',
        },
        {
            title: 'Kriek Lindemans / Geuze / Palm',
            price: '25 cl € 2,95',
        },
        {
            title: 'Jupiler N.A. (0% alcohol)',
            price: '25 cl € 2,95',
        },
    ]

    const menuWine = [
        {
            title: 'Movin Italia (White)',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title: 'Sauvignon (White) ',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title: 'Syrah (Red)',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title: 'Merlot ( Red)',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title:
                'House wine - White / Red / Rose',
            price: 'Glas - Verre € 4,50 / 50 cl € 11,90',
        },
    ]
    const menuWine_fr = [
        {
            title: 'Movin Italia (Blanc)',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title: 'Sauvignon (Blanc) ',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title: 'Syrah (Rouge)',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title: 'Merlot (Rouge)',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title:
                'Vin maison - Blanc / Rouge / Rosé',
            price: 'Glas - Verre € 4,50 / 50 cl € 11,90',
        },
    ]
    const menuWine_nl = [
        {
            title: 'Movin Italia (Wit)',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title: 'Sauvignon (Wit) ',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title: 'Syrah (Rood)',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title: 'Merlot (Rood)',
            price: 'Glas - Verre € 4,75 / 50 cl € 12,25',
        },
        {
            title:
                'Huiswijn - Wit / Rood / Rosé',
            price: 'Glas - Verre € 4,50 / 50 cl € 11,90',
        },
    ]

    const menuBubbles = [
        {
            title: 'Cava Portacelli (Spain)',
            price: 'Glas - Verre € 6,00 / 75 cl € 24,90',
        },
        {
            title: 'Prosecco',
            price: '75 cl € 31,90',
        },
        {
            title: 'Champagne',
            price: 'Glas - Verre € 11,00 / 37,5 cl € 25,90 / 75 cl € 46,90\n',
        },
    ]

    return (
        <>
            <div className='content_menu-items' id='a1'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Snacks / Starters"}
                          {lang === 'fr' && "Snacks / Entrées"}
                          {lang === 'nl' && "Snacks / Voorgerechten"}
                      </span>
                {lang === 'en' && menuStarters.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuStarters_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuStarters_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a2'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Salades"}
                          {lang === 'fr' && "Salades"}
                          {lang === 'nl' && "Salads"}
                      </span>
                {lang === 'en' && menuSalads.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuSalads_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuSalads_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a3'>
                <span className='content_menu-title'>Pasta - Pâtes</span>
                {lang === 'en' && menuPasta.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuPasta_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuPasta_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a4'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Fish"}
                          {lang === 'fr' && "Poisson"}
                          {lang === 'nl' && "Vis"}
                      </span>
                {lang === 'en' && menuFish.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuFish_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuFish_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a5'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Meat*/**"}
                          {lang === 'fr' && "Viande*/**"}
                          {lang === 'nl' && "Vlees*/**"}
                      </span>
                {lang === 'en' && menuMeat.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuMeat_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuMeat_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a6'>
                <span className='content_menu-title'>Desserts</span>
                {lang === 'en' && menuDesserts.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuDesserts_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuDesserts_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a7'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Pancakes"}
                          {lang === 'fr' && "Crêpes"}
                          {lang === 'nl' && "Pannenkoeken"}
                      </span>
                {lang === 'en' && menuPancakes.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuPancakes_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuPancakes_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a8'>
                <span className='content_menu-title'>Water - Eau</span>
                {lang === 'en' && menuWater.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuWater_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuWater_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a9'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Soft drinks"}
                            {lang === 'fr' && "Boissons froides"}
                            {lang === 'nl' && "Frisdranken"}
                        </span>
                {menuSoftDrinks.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a10'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Fruit juices"}
                            {lang === 'fr' && "Jus de fruits"}
                            {lang === 'nl' && "Fruitsappen"}
                        </span>
                {lang === 'en' && menuFruitJuices.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuFruitJuices_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuFruitJuices_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a11'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Hot drinks"}
                            {lang === 'fr' && "Boissons chaudes"}
                            {lang === 'nl' && "Warme dranken"}
                        </span>
                {lang === 'en' && menuHotDrinks.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuHotDrinks_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuHotDrinks_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a12'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Special tea"}
                            {lang === 'fr' && "Thé spécial"}
                            {lang === 'nl' && "Speciale thee"}
                        </span>
                {lang === 'en' && menuSpecialTea.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuSpecialTea_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuSpecialTea_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a13'>
                <span className='content_menu-title'>Cocktails</span>
                {menuCocktails.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a14'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Aperitif"}
                            {lang === 'fr' && "Apéritif"}
                            {lang === 'nl' && "Aperitief"}
                        </span>
                {menuAperitif.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a15'>
                <span className='content_menu-title'>Gin</span>
                {menuGin.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a16'>
                <span className='content_menu-title'>Ice Tea</span>
                {lang === 'en' && menuIceTea.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuIceTea_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuIceTea_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a17'>
                        <span className='content_menu-title'>
                            {lang === 'en' && "Limonade (homemade)"}
                            {lang === 'fr' && "Limonade (fait maison)"}
                            {lang === 'nl' && "Limonade (huisgemaakt)"}
                        </span>
                {lang === 'en' && menuLimonade.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuLimonade_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuLimonade_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a18'>
                <span className='content_menu-title'>Mocktails</span>
                {menuMocktails.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a19'>
                <span className='content_menu-title'>Smoothies</span>
                {lang === 'en' && menuSmoothies.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuSmoothies_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuSmoothies_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a20'>
                      <span className='content_menu-title'>
                        {lang === 'en' && "Beers"}
                          {lang === 'fr' && "Bières"}
                          {lang === 'nl' && "Bieren"}
                        </span>
                {menuBeers.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a21'>
                      <span className='content_menu-title'>
                          {lang === 'en' && "Wine"}
                          {lang === 'fr' && "Vin"}
                          {lang === 'nl' && "Wijn"}
                      </span>
                {lang === 'en' && menuWine.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'fr' && menuWine_fr.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
                {lang === 'nl' && menuWine_nl.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
            <br />
            <div className='content_menu-items' id='a22'>
                      <span className='content_menu-title'>
                          {lang === 'en' && "Bubbles"}
                          {lang === 'fr' && "Des bulles"}
                          {lang === 'nl' && "Bubbels"}
                      </span>
                {menuBubbles.map((item) => (
                    <div key={item.title} className='content_menu-item'>
                        <p>{item.title}</p>
                        <span>{item.price}</span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default MenuContent;