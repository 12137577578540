import React from 'react'
import Layout from '../layout/layout'
// import Logic from '../../Logic'
import Helmet from 'react-helmet'
import grimbergenImg from '../../images/home/grimbergen.webp'
import grimbergenImg_2 from '../../images/home/grimbergen_2.jpg'
import boetfortImg from '../../images/home/boetfort.webp'
import boetfortImg_2 from '../../images/home/boetfort_2.jpg'
import terkorbekeImg from '../../images/home/terkorbeke.jpg'
import terkorbekeImg_2 from '../../images/home/terkorbeke_2.jpg'
import cataringImg from '../../images/home/catering.jpg'
import cataringImg_2 from '../../images/home/catering_2.jpg'
import { NavLink } from 'react-router-dom'
import WindowWidth from "../WindowWidth";
import { useTranslation } from 'react-i18next'

const Home = () => {
  console.log(window.location.href)
  let { scrollPosition } = WindowWidth()
  const { t, i18n } = useTranslation()
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content='Restaurants grimbergend and boetfort description'
          data-react-helmet='true'
        />
        <title>Restaurants grimbergend and boetfort</title>
      </Helmet>
      <Layout />

      <main className='main'>

        <div className='container'>
          <section className='home-top'>
            <div className="home-top_title">
              {/*<h1>{t('top.title')}</h1>*/}
              <h1>
                {i18n.language === 'nl' && 'Unicus activiteiten.'}
                {i18n.language === 'fr' && 'Activités unicus.'}
                {i18n.language === 'en' && 'Unicus activities.'}
              </h1>
            </div>
            <div className='home-top__items'>
              <div className='home-top__item'>
                {/* <NavLink to='/boetfort'> */}
                {/*<a*/}
                {/*    href='https://www.thermae.com/en/bar-restaurant-thermae-boetfort'*/}
                {/*    target='_blank' rel="noreferrer">*/}
                <div className='img-wrapper'>
                  <img className='img_2' src={boetfortImg} alt='boetfort' />
                  <img className='img_1' src={boetfortImg_2} alt='boetfort' />
                  <div className='info'>
                    <NavLink to='/boetfort-menu'>
                      <span className='button'>link to menu</span>
                    </NavLink>
                    <a
                      href='https://www.thermae.com/en/bar-restaurant-thermae-boetfort'
                      target='_blank'
                      rel='noreferrer'>
                      link to site
                    </a>
                  </div>
                  <div className='home-top__item_text'>
                    {/*<span>Hotel & wellness</span>*/}
                    {/*<br/>*/}
                    <div className='wrap_text'>
                      <span>Thermae Boetfort</span>
                    </div>
                  </div>
                  {/*</a>*/}
                  {/* </NavLink> */}
                </div>
              </div>
              <div className='home-top__item'>
                {/*<a*/}
                {/*    href='https://www.thermae.com/en/bar-restaurant-thermae-grimbergen'*/}
                {/*    target='_blank' rel="noreferrer">*/}
                <div className='img-wrapper'>
                  <img className='img_2' src={grimbergenImg} alt='grimbergen' />
                  <img className='img_1' src={grimbergenImg_2} alt='grimbergen' />
                  <div className='info'>
                    <NavLink to='/grimbergen-menu'>
                      <span className='button'>link to menu</span>
                    </NavLink>
                    <a
                      href='https://www.thermae.com/en/bar-restaurant-thermae-grimbergen'
                      target='_blank'
                      rel='noreferrer'>
                      link to site
                    </a>
                  </div>
                  <div className='home-top__item_text'>
                    {/*<span>Hotel & wellness</span>*/}
                    {/*<br/>*/}
                    <div className='wrap_text'>
                      <span>Thermae Grimbergen</span>
                    </div>
                  </div>
                  {/*</a>*/}
                </div>
              </div>
              <div className='home-top__item'>
                {/*<a href='http://www.terkorbeke.be/diensten' target='_blank' rel="noreferrer">*/}
                <div className='img-wrapper'>
                  <img className='img_2' src={terkorbekeImg} alt='terkorbeke' />
                  <img className='img_1' src={terkorbekeImg_2} alt='unicus' />
                  <div className='info'>
                    <button className='button'>link to menu</button>
                    <a href='https://terkorbeke-restaurant.be/' target='_blank' rel='noreferrer'>
                      link to site
                    </a>
                  </div>
                  <div className='home-top__item_text'>
                    <div className='wrap_text'>
                      <span>Ter Korbeke</span>
                    </div>

                    {/*<br/>*/}
                    {/*<span>Ter Korbeke</span>*/}
                  </div>
                  {/*</a>*/}
                </div>
              </div>
              <div className='home-top__item unicus'>
                {/*<a href='https://unicus-delta.vercel.app/' target='_blank' rel="noreferrer">*/}
                <div className='img-wrapper'>
                  <img className='img_2' src={cataringImg_2} alt='unicus' />

                  <img className='img_1' src={cataringImg} alt='unicus' />

                  <div className='info'>
                    {/*<button className="button">link to menu</button>*/}
                    <a href='https://unicus-catering.com/' target='_blank' rel='noreferrer'>
                      link to site
                    </a>
                  </div>
                  <div className='home-top__item_text'>
                    <div className='wrap_text'>
                      <span>Unicus catering</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="bg"/>
      </main>
    </>
  )
}

export default Home
